import formatDate from "../../utils/formatDate";

export default function preparePatientStateHistory(patient, monthThreshold, calculationDate) {
	const stateHistory = patient.patientStateValueSchedule.valuePeriods;
	const thresholdDate = calculationDate;
	const dateStringForParsingFormat = stateHistory[0].startDate
		.replace(".000+0000", " GMT")
		.replace(/-/g, "/")
		.replace("T", " ");
	thresholdDate.setMonth(thresholdDate.getMonth() - monthThreshold);
	if (stateHistory.length < 2 || new Date(dateStringForParsingFormat) < thresholdDate) {
		return [];
	}
	return stateHistory.map(prepareEntry);

	function prepareEntry(valuePeriod) {
		let stateDatesString = `${formatDate(valuePeriod.startDate)} - `;
		if (valuePeriod.endDate) {
			stateDatesString = `${stateDatesString}${formatDate(valuePeriod.endDate)}`;
		}
		return {
			stateName: valuePeriod.value.name,
			stateDates: stateDatesString
		};
	}
}
