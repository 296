import { ofContext, contextExists } from "../../utils/preferencesHelpers";
import hasLink from "../../utils/hasLink";

const findPatientMenuItem = (patient, menuStructure, filtersContext) => {
	const patientMenu = menuStructure.patient(patient.id);
	return patientMenu.find((item) => item.contextName === filtersContext);
};

function verifyLink(linkNameContainer, objectWithLinks) {
	const linkName = linkNameContainer.requiresLink;
	return linkName ? hasLink(objectWithLinks, linkName) : true;
}

function findSubmenu(patientMenuItem, filterId) {
	if (filterId) {
		return patientMenuItem.submenu.find((item) => item.id === filterId);
	}
	return patientMenuItem.submenu.find((item) => item.url === patientMenuItem.defaultUrl);
}

class PatientPage {
	constructor(patient, patientPreferences, menuStructure, filtersContext, filterId = null) {
		this.patient = patient;
		this.patientPreferences = patientPreferences;
		this.menuStructure = menuStructure;
		this.filtersContext = filtersContext;
		this.filterId = filterId;
		this.patientMenuItem = findPatientMenuItem(patient, menuStructure, filtersContext);
	}

	isDefaultFilterDefined = () => { throw new Error("Not implemented"); };

	isPageDisplayable = () => { throw new Error("Not implemented"); };

	isFilterDefined = () => { throw new Error("Not implemented"); };

	getUrl = () => { throw new Error("Not implemented"); };
}

class DynamicFilterPatientPage extends PatientPage {
	isDefaultFilterDefined = () => typeof ofContext(this.patientPreferences, this.filtersContext).default !== "undefined";

	isPageDisplayable = () => {
		const filtersContextExists = contextExists(this.patientPreferences, this.filtersContext);
		if (this.patientMenuItem) {
			return filtersContextExists && verifyLink(this.patientMenuItem, this.patient);
		}
		return filtersContextExists;
	};

	isFilterDefined = () => (!!ofContext(this.patientPreferences, this.filtersContext).withId(this.filterId));

	getUrl = () => {
		if (this.patientMenuItem.filterUrl) {
			const filterUrlTemplate = this.patientMenuItem.filterUrl;
			if (this.filterId) {
				return filterUrlTemplate.replace("{id}", this.filterId);
			}
			if (this.isDefaultFilterDefined()) {
				const defaultFilterId = ofContext(this.patientPreferences, this.filtersContext).default.id;
				return filterUrlTemplate.replace("{id}", defaultFilterId);
			}
		}
		return this.patientMenuItem.defaultUrl;
	};
}

class CitizenPathwayPage extends DynamicFilterPatientPage {
	isPageDisplayable = () => true;
}

class FixedFiltersPage extends PatientPage {
	isDefaultFilterDefined = () => {
		if (this.patientMenuItem) {
			return typeof this.patientMenuItem.defaultUrl !== "undefined";
		}
		return false;
	};

	isFilterDefined = () => {
		if (this.filterId) {
			if (this.patientMenuItem) {
				return typeof findSubmenu(this.patientMenuItem, this.filterId) !== "undefined";
			}
		}
		return false;
	};

	isPageDisplayable = () => {
		if (this.patientMenuItem && verifyLink(this.patientMenuItem, this.patient)) {
			const subMenuItem = findSubmenu(this.patientMenuItem, this.filterId);
			return subMenuItem && verifyLink(subMenuItem, this.patient);
		}
		return false;
	};

	getUrl = () => {
		if (this.isFilterDefined()) {
			return findSubmenu(this.patientMenuItem, this.filterId).url;
		}
		if (this.isDefaultFilterDefined()) {
			return this.patientMenuItem.defaultUrl;
		}
		throw new Error("No url found");
	};
}

export default class PatientPageProvider {
	constructor(menuStructure) {
		this.menuStrucutre = menuStructure;
	}

	getPatientPageByFiltersContext = (patient, patientPreferences, filterContext, filterId) => {
		switch (filterContext) {
			case "CITIZEN_PATHWAY":
				return new CitizenPathwayPage(patient, patientPreferences, this.menuStrucutre, filterContext, filterId);
			case "HCL": case "CORRESPONDENCE": case "MEDICINE":
				return new FixedFiltersPage(patient, patientPreferences, this.menuStrucutre, filterContext, filterId);
			default:
				return new DynamicFilterPatientPage(patient, patientPreferences, this.menuStrucutre, filterContext, filterId);
		}
	};
}
