import on from "../../../utils/on";
import NexusVideoCtrl from "./nexusVideoCtrl";

export default class NexusVideoActionBinder {
	bindActions(linkHolder) {
		const ctrl = new NexusVideoCtrl(linkHolder);
		bindClickActions(ctrl);
	}
}

function bindClickActions(ctrl) {
	const onClick = on("click");

	onClick
		.callback(callback)
		.bindByClassName("nexus-video-status-clickable-card");

	function callback(e) {
		e.stopPropagation();
		e.preventDefault();
		ctrl.initCall();
	}
}
