/* eslint-disable no-param-reassign,prefer-destructuring */
import hasLink from "../utils/hasLink";
import renderConditionChecker from "./renderConditionChecker";

function forEach(array, callback) {
	if (typeof array === "undefined") {
		return array;
	}
	return array.forEach(callback);
}

function remove(array, predicate) {
	if (typeof array === "undefined") {
		return array;
	}
	for (let i = 0; i < array.length; i += 1) {
		if (predicate(array[i])) {
			array.splice(i, 1);
			i -= 1;
		}
	}
	return array;
}

function contextExists(filters, context) {
	return typeof filters[context] !== "undefined";
}

function targetSelf(menu, menuStructure, location) {
	const currentApp = menuStructure.getApplicationNameForPath(location.getPathname());
	forEach(menu, (menuItem) => {
		const menuItemApp = menuStructure.getApplicationNameForPath(menuItem.url);
		menuItem.isCurrentApp = currentApp === menuItemApp;
		if (menuItem.submenu) {
			targetSelf(menuItem.submenu, menuStructure, location);
		}
	});
}

function initialize(objectWithLinks, filters, menuStructure, menu, location, onEmptyMenuFunction, layoutConfiguration, html5) {
	const renderConditionCheckerInstance = renderConditionChecker(layoutConfiguration.features);

	checkRequirementsTopMenu(menu, objectWithLinks);
	fillWithFilters(menu, filters, onEmptyMenuFunction, renderConditionCheckerInstance);
	filterMenuVariants(menu, menuStructure.defaultMenuVariants, layoutConfiguration.menuVariants ? layoutConfiguration.menuVariants : {});
	filterByRenderConditions(menu, renderConditionCheckerInstance);
	clearEmpty(menu);
	initializeTarget(menu);
	html5Safe(menu, html5);
	targetSelf(menu, menuStructure, location);
	return menu;
}

function checkRequirementsTopMenu(menuTree, objectWithLinks) {
	remove(menuTree, (topMenu) => (requiresLinkDefined(topMenu) && !hasLink(objectWithLinks, topMenu.requiresLink))
		|| noSubMenuHasRequiresLinkDefined(topMenu));

	forEach(menuTree, (topMenu) => {
		if (topMenu.submenu) {
			checkRequirementsSubMenu(topMenu.submenu, objectWithLinks);
		}
	});
}

function requiresLinkDefined(objectWithLinks) {
	return typeof objectWithLinks.requiresLink === "string";
}

function noSubMenuHasRequiresLinkDefined(topMenu) {
	return Array.isArray(topMenu.submenu) && !topMenu.submenu.some((submenu) => requiresLinkDefined(submenu));
}

function checkRequirementsSubMenu(subMenuTree, objectWithLinks) {
	remove(subMenuTree, (subMenuItem) => {
		const hasRequiredLink = requiresLinkDefined(subMenuItem);
		if (!hasRequiredLink) {
			// eslint-disable-next-line no-console
			console.warn(`RequiresLink is required for sub-menu item: ${subMenuItem.title}`);
			return true;
		}
		return !hasLink(objectWithLinks, subMenuItem.requiresLink);
	});

	forEach(subMenuTree, (subMenuItem) => {
		if (subMenuItem.submenu) {
			checkRequirementsSubMenu(subMenuItem.submenu, objectWithLinks);
		}
	});
}

function html5Safe(menuItems, html5) {
	forEach(menuItems, (menuItem) => {
		if (!html5) {
			menuItem.url = `#${menuItem.url}`;
		}

		if (menuItem.submenu) {
			html5Safe(menuItem.submenu, html5);
		}
	});
}

function filterMenuVariants(menuItems, defaultMenuVariants, environmentVariants) {
	mergeDefaultMenuVariants(menuItems, defaultMenuVariants);
	remove(menuItems, (menuItem) => {
		if (!canShowMenu(menuItem.menuVariants, environmentVariants)) {
			return true;
		}
		filterMenuVariants(menuItem.submenu, defaultMenuVariants, environmentVariants);
		return false;
	});
}

function mergeDefaultMenuVariants(menuItems, defaultMenuVariants) {
	forEach(menuItems, (menuItem) => {
		const menuVariants = {};
		Object.keys(defaultMenuVariants)
			.forEach((key) => {
				menuVariants[key] = defaultMenuVariants[key];
			});
		forEach(menuItem.menuVariants, (menuVariant) => {
			menuVariants[menuVariant] = true;
		});
		menuItem.menuVariants = menuVariants;
	});
}

function canShowMenu(menuVariants, environmentVariants) {
	return !Object.keys(menuVariants).some((key) => {
		const menuVariant = !!menuVariants[key];
		const environmentVariant = !!environmentVariants[key];
		return menuVariant !== environmentVariant;
	});
}

function filterByRenderConditions(menuItems, conditionChecker) {
	remove(menuItems, (menuItem) => {
		if (!conditionChecker.check(menuItem.renderCondition)) {
			return true;
		}
		filterByRenderConditions(menuItem.submenu, conditionChecker);
		return false;
	});
}

function createSubmenuItem(filter, url, topMenu, urlWildcardPattern) {
	return {
		title: filter.name,
		titleTranslated: filter.name,
		url,
		urlWildcardPattern,
		menuVariants: topMenu.menuVariants
	};
}

function handleFillWithFilter(filters, topMenu) {
	const items = filters[topMenu.fillWithFilter];

	forEach(items, (filter) => {
		const url = topMenu.filterUrl.replace("{id}", filter.id ? filter.id : "");
		let urlWildcardPattern = null;
		if (topMenu.urlWildcard) {
			urlWildcardPattern = buildRegexPattern(topMenu.urlWildcard.replace("{id}", filter.id ? filter.id : ""));
		}

		if (filter.defaultPreference) {
			topMenu.url = url;
		}

		topMenu.submenu.push(createSubmenuItem(filter, url, topMenu, urlWildcardPattern));
	});
}

function submenuByTitleTranslatedComparator() {
	return (submenuA, submenuB) => {
		if (submenuA.titleTranslated < submenuB.titleTranslated) {
			return -1;
		}
		if (submenuA.titleTranslated > submenuB.titleTranslated) {
			return 1;
		}
		return 0;
	};
}

function handleFillWithMultipleFilters(filters, topMenu, conditionChecker) {
	forEach(Object.keys(topMenu.fillWithMultipleFilters), (context) => {
		const filterConfig = topMenu.fillWithMultipleFilters[context];

		if (conditionChecker.check(filterConfig.renderCondition)) {
			forEach(filters[context], (filter) => {
				const url = filterConfig.url.replace("{id}", filter.id ? filter.id : "");

				topMenu.submenu.push(createSubmenuItem(filter, url, topMenu));
			});
		}
	});

	topMenu.submenu.sort(submenuByTitleTranslatedComparator());
}

function fillWithFilters(menuItems, filters, onEmptyMenuFunction, conditionChecker) {
	forEach(menuItems, (topMenu) => {
		if (!topMenu.submenu) {
			topMenu.submenu = [];
		}

		topMenu.url = topMenu.defaultUrl;

		if (topMenu.fillWithFilter) {
			handleFillWithFilter(filters, topMenu);
		} else if (topMenu.fillWithMultipleFilters) {
			handleFillWithMultipleFilters(filters, topMenu, conditionChecker);
		}

		if (topMenu.submenu.length === 0 && onEmptyMenuFunction) {
			onEmptyMenuFunction(topMenu);
		}
	});
}

function buildRegexPattern(antPattern) {
	const regexPattern = antPattern.replace("**", ".+")
		.replace("*", "[^/]+")
		.replace(".+", ".*");
	return new RegExp(`^${regexPattern}`, "g");
}

function clearEmpty(menuTree) {
	remove(menuTree, (itm) => itm.submenu.length <= 0);
}

function initializeTarget(menuItems) {
	forEach(menuItems, (topMenuItem) => {
		forEach(topMenuItem.submenu, (subMenuItem) => {
			if (!subMenuItem.target) {
				subMenuItem.target = "";
			}
		});
	});
}

export default class MenuBuilder {
	constructor(menuStructure, location) {
		this.menuStructure = menuStructure;
		this.noPreferenceUrls = menuStructure.noPreferenceUrls();
		this.location = location;
	}

	build(home, filters, html5, $translate, layoutConfiguration) {
		const noPreferenceUrls = this.noPreferenceUrls;
		const onEmptyMenuFunction = (topMenu) => {
			if (contextExists(filters, topMenu.fillWithFilter)) {
				topMenu.submenu.push({
					title: $translate.instant("mainMenu_createPreference"),
					url: noPreferenceUrls.main(topMenu.fillWithFilter),
					menuVariants: topMenu.menuVariants
				});
			}
		};

		return initialize(home, filters, this.menuStructure, this.menuStructure.main(), this.location, onEmptyMenuFunction, layoutConfiguration, html5);
	}

	buildPatient(patient, filters, html5, $translate, params) {
		const noPreferenceUrls = this.noPreferenceUrls;
		const onEmptyMenuFunction = (topMenu) => {
			if (contextExists(filters, topMenu.fillWithFilter)) {
				topMenu.submenu.push({
					title: $translate.instant("mainMenu_createPreference"),
					url: noPreferenceUrls.patient(topMenu.fillWithFilter, patient.id),
					menuVariants: topMenu.menuVariants
				});
			}
		};

		return initialize(patient, filters, this.menuStructure, this.menuStructure.patient(patient.id), this.location, onEmptyMenuFunction, params, html5);
	}

	buildAdmin(home, filters, html5, $translate, params) {
		const noPreferenceUrls = this.noPreferenceUrls;
		const onEmptyMenuFunction = (topMenu) => {
			if (contextExists(filters, topMenu.fillWithFilter)) {
				topMenu.submenu.push({
					title: $translate.instant("mainMenu_createPreference"),
					titleTranslated: $translate.instant("mainMenu_createPreference"),
					url: noPreferenceUrls.admin(topMenu.fillWithFilter),
					menuVariants: topMenu.menuVariants
				});
			}
		};

		return initialize(home, filters, this.menuStructure, this.menuStructure.admin(), this.location, onEmptyMenuFunction, params, html5);
	}
}
