/* eslint-disable no-underscore-dangle */

import hasLink from "./hasLink";

export default function getLink(objectWithLink, linkName) {
	if (hasLink(objectWithLink, linkName)) {
		return objectWithLink._links[linkName].href;
	}
	return null;
}
