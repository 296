/* eslint-disable no-unused-vars, no-console, prefer-rest-params */
import MenuBuilder from "../menuBuilder/menuBuilder";
import Location from "../support/location";
import ErrorPopup from "../errorHandling/errorPopup";
import pageWasRefreshed from "../utils/pageWasRefreshed";
import BaseTemplateRenderer from "./base/baseTemplateRenderer";
import MenuRenderer from "./menu/menuRenderer";
import CoreActionBinder from "./menu/coreActionBinder";
import logoutDefault from "./menu/logout";

const defaultConfig = {
	logout: logoutDefault,
	useFlexLayout: false
};

export default class NexusLayoutCore {
	constructor(nexusApiCache, menuStructure, configuration) {
		this.eventBus = nexusApiCache.eventBus;
		this.errorPopup = new ErrorPopup();
		this.location = new Location();
		this.home = nexusApiCache.home;
		this.preference = nexusApiCache.preference;
		const menuBuilder = new MenuBuilder(menuStructure, this.location);
		const config = this.mergeWithDefaultConfig(configuration);

		this.coreActionBinder = new CoreActionBinder(
			nexusApiCache,
			menuStructure,
			this.location,
			config.logout,
			this.errorPopup
		);
		this.container = config.container;
		this.baseTemplateRenderer = new BaseTemplateRenderer(this.container, config.useFlexLayout);
		this.menuRenderer = new MenuRenderer(menuBuilder, this.container);

		if (pageWasRefreshed()) {
			this.preference.evict();
			this.home.evict();
		}
	}

	mergeWithDefaultConfig(configuration) {
		return {
			...defaultConfig,
			...configuration
		};
	}

	loadHomeAndPreferences(params) {
		const homePromise = this.home.get()
			.then((result) => result)
			.catch((reason) => {
				throw reason;
			});

		const preferencePromise = this.preference.get()
			.then((result) => result)
			.catch((reason) => {
				throw reason;
			});

		Promise.all([homePromise, preferencePromise])
			.then(
				([home, preference]) => {
					this.draw(home, preference, params);
				},
				(error) => {
					if (error.response.data.reason === "ProfessionalDoesNotExist"
						|| error.response.data.reason === "ExpectedSingleButMultipleProfessionalsFound"
						|| error.response.data.reason === "MissingUpn") {
						/*
							Target solution should be that every error is handled via log out.
							Displaying error popup and hanging up application leaves very bad
							user experience
						 */
						this.coreActionBinder.logout(error.response.data.reason);
					} else {
						this.errorPopup.handleError(error);
					}
				}
			);
	}

	drawLayout(layoutConfiguration) {
		this.baseTemplateRenderer.render(layoutConfiguration);
		if (this.home.isCached() && this.preference.isCached()) {
			this.draw(this.home.getCached(), this.preference.getCached(), layoutConfiguration);
		} else {
			this.loadHomeAndPreferences(layoutConfiguration);
		}

		this.eventBus.on("finishLoadingHome", () => {
			this.reload(layoutConfiguration);
		});
		this.eventBus.on("finishLoadingPreference", () => {
			this.reload(layoutConfiguration);
		});
	}

	draw(home, preference, layoutConfiguration) {
		this.drawMenu(home, preference, layoutConfiguration);
	}

	drawMenu(home, preference, layoutConfiguration) {
		const professionalId = home.professional ? home.professional.id : undefined;
		this.menuRenderer.render(home, preference, layoutConfiguration);
		this.coreActionBinder.bindActions(this.container ? this.container : document.getElementById(layoutConfiguration.nexusLayoutElementId),
			professionalId, layoutConfiguration.features);
		this.coreActionBinder.initRecentItemsForMenu(professionalId, layoutConfiguration.features);
	}

	reload(layoutConfiguration) {
		this.loadHomeAndPreferences(layoutConfiguration);
	}
}
