/* eslint-disable no-underscore-dangle */
import on from "../../utils/on";
import PatientCardsCtrl from "./patientCardsCtrl";

export default class PatientOverviewActionBinder {
	constructor(eventBus, apiAccess, modalService, defaultPatientPage, errorPopup, container) {
		this._eventBus = eventBus;
		this._modalService = modalService;
		this._apiAccess = apiAccess;
		this._defaultPatientPage = defaultPatientPage;
		this._errorPopup = errorPopup;
		this.container = container;
	}

	bindActions(patientOverview, patientLayoutElementId, expanded, useSideBarGlobalState, prefix) {
		const patientCardsCtrl = new PatientCardsCtrl(
			patientOverview,
			this.getElement(patientLayoutElementId),
			this._eventBus,
			this._apiAccess,
			expanded,
			useSideBarGlobalState,
			this._modalService,
			this._defaultPatientPage,
			this._errorPopup
		);
		bindClickActions(patientCardsCtrl, prefix, this.container);
	}

	getElement(elementId) {
		if (this.container) {
			return this.container.querySelector(`#${elementId}`);
		}
		return document.getElementById(elementId);
	}
}

function bindClickActions(patientCardsCtrl, prefix, container) {
	const onClick = on("click");

	onClick
		.callback(patientCardsCtrl.changePatient)
		.bindById(`${prefix}patient-basic-data-card`, container);

	onClick
		.callback(patientCardsCtrl.changeDoctorContact)
		.bindById(`${prefix}patient-doctor-contact-card`, container);

	onClick
		.callback(patientCardsCtrl.editRelativeContact)
		.bindByClassName(`${prefix}patient-relative-contact-card`, container);

	onClick
		.callback(patientCardsCtrl.showPatientForm)
		.bindByClassName(`${prefix}patient-form-card`, container);

	onClick
		.callback(patientCardsCtrl.toggleCards)
		.bindById(`${prefix}citizenBannerToggleButton`, container);
}
