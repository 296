import template from "lodash/template";
import translate from "../../support/translate";
import htmlToElement from "../../utils/htmlToElement";
import nexusMenuMobileTemplate from "./template/nexus-layout-menu-mobile.tmpl.html";
import nexusMenuDesktopTemplate from "./template/nexus-layout-menu-desktop.tmpl.html";
import nexusTopbarTemplate from "./template/nexus-layout-topbar.tmpl.html";
import prepareTemplateParams from "./prepareTemplateParams";

const compiledMobileMenu = template(nexusMenuMobileTemplate);
const compiledDesktopMenu = template(nexusMenuDesktopTemplate);
const compiledTopBarMenu = template(nexusTopbarTemplate);

const mobileMenuId = "nexus-layout-core-menu-mobile";
const desktopMenuId = "nexus-layout-core-menu-desktop";
const topBarId = "nexus-layout-core-topbar";

function renderTemplate(container, compiledTemplate, templateParams) {
	while (container.hasChildNodes()) {
		container.removeChild(container.lastChild);
	}

	const compiledTemplate2 = compiledTemplate(templateParams);
	const element = htmlToElement(compiledTemplate2);
	container.appendChild(element);

	if (!templateParams.hasMenu) {
		container.classList.add("no-menu");
	}
}

export default class MenuRenderer {
	constructor(menuBuilder, container) {
		this.menuBuilder = menuBuilder;
		this.container = container;
	}

	render(home, preference, layoutConfiguration) {
		const menuData = this.menuBuilder.build(home, preference, true, translate, layoutConfiguration);
		const adminMenuData = this.menuBuilder.buildAdmin(home, preference, true, translate, layoutConfiguration);

		const templateParams = prepareTemplateParams(home, menuData, adminMenuData, layoutConfiguration);

		renderTemplate(this.getElementById(mobileMenuId), compiledMobileMenu, templateParams);
		renderTemplate(this.getElementById(desktopMenuId), compiledDesktopMenu, templateParams);
		renderTemplate(this.getElementById(topBarId), compiledTopBarMenu, templateParams);
	}

	getElementById(id) {
		if (this.container) {
			return this.container.querySelector(`#${id}`);
		}
		return document.getElementById(id);
	}
}
