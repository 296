import translate from "../../support/translate";
import hasLink from "../../utils/hasLink";

export default function prepareTemplateParams(home, menuData, adminMenuData, params) {
	return {
		menuData,
		adminMenuData,
		professionalName: home.professional.fullName,
		canUpdateSettings: hasLink(home.professional, "configuration"),
		headerColor: params.headerColor,
		hasMenu: menuData.length > 0,
		hasAdminMenuActiveSubmenus: adminMenuData.length > 0,
		hasProfileEnabled: params.features && params.features["ui.profiles"],
		translate(key) {
			return translate.instant(key);
		}
	};
}
