export default class ModalService {
	constructor(patientDialogsUrls, location) {
		this.patientDialogsUrls = patientDialogsUrls;
		this.location = location;
	}

	returnUrl() {
		return this.location.getFullPath();
	}

	changePatient(patientId) {
		const changePatientUrl = this.patientDialogsUrls.changePatient(patientId, this.returnUrl());
		this.changeLocation(changePatientUrl);
	}

	changeProfessionalContact(patientId, contactId) {
		const changeContactUrl = this.patientDialogsUrls.changeProfessionalContact(patientId, contactId, this.returnUrl());
		this.changeLocation(changeContactUrl);
	}

	changeOrganizationContact(patientId, contactId) {
		const changeContactUrl = this.patientDialogsUrls.changeOrganizationContact(patientId, contactId, this.returnUrl());
		this.changeLocation(changeContactUrl);
	}

	editRelativeContact(patientId, relativeContactId) {
		const url = this.patientDialogsUrls.editRelativeContactDetails(patientId, relativeContactId, this.returnUrl());
		this.changeLocation(url);
	}

	editPatientRelativeContact(patientId, relativeContactId) {
		const url = this.patientDialogsUrls.editPatientRelativeContactDetails(patientId, relativeContactId, this.returnUrl());
		this.changeLocation(url);
	}

	showFlowerChart(patientId) {
		const showFlowerChartUrl = this.patientDialogsUrls.showFlowerChart(patientId, this.returnUrl());
		this.changeLocation(showFlowerChartUrl);
	}

	showForm(formId) {
		const showFormUrl = this.patientDialogsUrls.showForm(formId, this.returnUrl());
		this.changeLocation(showFormUrl);
	}

	showPhoto(patientId) {
		const showFormUrl = this.patientDialogsUrls.showPhoto(patientId, this.returnUrl());
		this.changeLocation(showFormUrl);
	}

	changeLocation(dialogUrl) {
		this.location.path(dialogUrl.url, dialogUrl.params);
	}
}
