export default function on(eventName) {
	return {
		callback(callback) {
			const bindActions = {
				bindById(id, container = document) {
					const element = container.querySelector(`[id='${id}']`);
					if (element) {
						element.addEventListener(eventName, (e) => (callback(e, element)));
					}
					return bindActions;
				},
				bindByClassName(className, container = document) {
					const elements = [...container.getElementsByClassName(className)];
					if (elements) {
						elements.forEach((element) => element.addEventListener(eventName, (e) => (callback(e, element))));
					}
					return bindActions;
				},
				bind(element) {
					element.addEventListener(eventName, (e) => (callback(e, element)));
					return bindActions;
				}
			};
			return bindActions;
		}
	};
}
