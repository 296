import template from "lodash/template";
import translate from "../../../support/translate";
import htmlToElement from "../../../utils/htmlToElement";
import removeChildFromElement from "../../../utils/removeChildFromElement";
import contentTemplate from "./average-visited-time-table.tmpl.html";

const formatVisitedTime = (time) => {
	if (time) {
		return (+time).toFixed(2);
	}
	return "-";
};

const params = (averageVisitedTime) => ({
	averageVisitedTime,
	formatVisitedTime,
	translate(key) {
		return translate.instant(key);
	}
});

const compiledTemplate = template(contentTemplate);

export default class AverageVisitedTimeTableRenderer {
	render(containerId, averageVisitedTime) {
		const container = document.getElementById(containerId);
		if (container) {
			const content = htmlToElement(compiledTemplate(params(averageVisitedTime)));
			container.appendChild(content);
		}
	}

	remove(containerId) {
		const containerElement = document.getElementById(containerId);
		if (containerElement) {
			removeChildFromElement(containerElement);
		}
	}
}
