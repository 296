function PatientOverviewCtrl(patient, modalService) {
	const vm = this;
	vm.changePatient = changePatient;
	vm.showPhoto = showPhoto;

	function changePatient() {
		modalService.changePatient(patient.id);
	}

	function showPhoto() {
		modalService.showPhoto(patient.id);
	}
}

export default PatientOverviewCtrl;
