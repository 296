/*
  onClick handler for all <A> in the core layout DOM. Prevents from reloading application
  when menu items are clicked. Instead forwards URL change to the application location service.

  Code based on angular implementation.
*/

const IGNORE_URI_REGEXP = /^\s*(javascript|mailto):/i;

function nodeName(element) {
	return element.tagName.toLowerCase();
}

function ancestor(inputElm, tagName) {
	let elm = inputElm;
	while (elm && nodeName(elm) !== tagName) {
		elm = elm.parentElement;
	}
	return elm;
}

export default class ClickLink {
	constructor(location) {
		this.location = location;
	}

	handle(event) {
		// eslint-disable-next-line eqeqeq
		const openInNewWindow = event.ctrlKey || event.metaKey || event.shiftKey || event.which == 2 || event.button == 2;
		if (openInNewWindow) {
			return;
		}

		let elm = event.target;
		elm = ancestor(elm, "a");
		if (!elm) {
			return;
		}

		const absHref = elm.getAttribute("href");
		if (IGNORE_URI_REGEXP.test(absHref)) {
			return;
		}

		const linkTarget = elm.getAttribute("target");
		const openInCurrentTab = !linkTarget || linkTarget === "_self";

		if (absHref && openInCurrentTab && !event.defaultPrevented) {
			// preventing location change
			event.preventDefault();
			// preventing from propagation to the body where angular usually waits on click on A tags
			event.stopPropagation();

			this.location.href(absHref);
		}
	}
}
