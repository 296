/* eslint-disable no-underscore-dangle */
import ClickLink from "../../support/clickLink";
import on from "../../utils/on";
import MenuSearch from "../search/menuSearch";
import MenuCtrl from "./menuCtrl";
import toggleMenuCtrl from "./toggleMenu";
import ModalService from "../../patient/utils/modalService";

export default class CoreActionBinder {
	constructor(nexusApiCache, menuStructure, location, logout, errorPopup) {
		this.menuCtrl = new MenuCtrl(toggleMenuCtrl, nexusApiCache.eventBus, location, menuStructure.professionalDialogsUrls());
		this.modalService = new ModalService(menuStructure.patientDialogsUrls(), location);
		this.menuSearch = new MenuSearch(nexusApiCache.home, nexusApiCache.apiAccess, location, menuStructure.searchUrls(), errorPopup, this.modalService);
		this.logout = logout;
		this.clickLink = new ClickLink(location);
	}

	bindActions(container, professionalId, features) {
		bindSearchFormActions(this.menuSearch, container);
		bindClickOnLinks(this.clickLink, container);
		bindClickOnNexusCoreLayout(container);
		bindToggleMenuAction(this.menuCtrl, container);
		bindMouseOverActions(this.menuCtrl, container);
		bindMouseLeaveActions(this.menuCtrl, container);
		bindMouseEnterActions(this.menuCtrl, container);
		bindClickActions(this.menuCtrl, this.logout, professionalId, features, container);
	}

	initRecentItemsForMenu(professionalId, features) {
		this.menuCtrl.initRecentItemsForMenu(professionalId, features);
	}
}

function bindClickOnLinks(clickLink, container) {
	on("click")
		.callback((e) => clickLink.handle(e))
		.bindById("nexus-layout-core-menu-mobile", container)
		.bindById("nexus-layout-core-menu-desktop", container)
		.bindById("nexus-layout-core-topbar", container);
}

function bindClickOnNexusCoreLayout(element) {
	on("click")
		.callback(noop)
		.bind(element);
}

function bindToggleMenuAction(menuCtrl, container) {
	on("click")
		.callback(menuCtrl.toggleMenu)
		.bindById("nexus-layout-menu-mobile-toggle-menu", container)
		.bindById("nexus-layout-menu-desktop-toggle-menu", container)
		.bindById("nexus-layout-menu-toggle-topbar", container);
}

function bindMouseOverActions(menuCtrl, container) {
	const onMouseOver = on("mouseover", container);

	onMouseOver
		.callback(menuCtrl.mouseOver)
		.bindByClassName("nexus-layout-menu-main", container);
	onMouseOver
		.callback(menuCtrl.adminMenuMouseOver)
		.bindById("nexus-layout-top-menu-admin-menu-li", container);
	onMouseOver
		.callback(menuCtrl.accountMenuMouseOver)
		.bindById("professionalTopBarSettings", container);
}

function bindMouseLeaveActions(menuCtrl, container) {
	const onMouseLeave = on("mouseleave");

	onMouseLeave
		.callback(menuCtrl.mouseLeave)
		.bindByClassName("nexus-layout-menu-main", container);
	onMouseLeave
		.callback(menuCtrl.iconMouseLeave)
		.bindByClassName("nexus-layout-desktop-menu-a-icon", container);
}

function bindMouseEnterActions(menuCtrl, container) {
	on("mouseenter")
		.callback(menuCtrl.iconMouseEnter)
		.bindByClassName("nexus-layout-desktop-menu-a-icon", container);
}

function bindClickActions(menuCtrl, logout, professionalId, features, container) {
	const onClick = on("click");

	onClick
		.callback(storeClickedItem)
		.bindByClassName("nexus-layout-left-menu-sub-element", container);

	onClick
		.callback(menuCtrl.hideMenu)
		.bindByClassName("subheader-url", container)
		.bindByClassName("nexus-layout-left-menu-sub-element", container)
		.bindByClassName("nexus-layout-mobile-menu-submenu-link", container);

	onClick
		.callback(menuCtrl.adminMenuToggle)
		.bindById("nexus-layout-top-menu-admin-menu-li", container);

	onClick
		.callback(menuCtrl.accountMenuToggle)
		.bindById("professionalTopBarSettings", container);

	onClick
		.callback(menuCtrl.updateProfessional)
		.bindById("editMyProfil", container)
		.bindById("nexus-layout-editMyProfil-mobile", container);

	onClick
		.callback(menuCtrl.editPreferences)
		.bindById("editPreferences", container)
		.bindById("nexus-layout-editPreferences-mobile", container);

	onClick
		.callback(updateProfessionalSystemSettings)
		.bindById("editSystemSettings", container)
		.bindById("nexus-layout-editSystemSettings-mobile", container);

	onClick
		.callback(menuCtrl.editPerson)
		.bindById("editPerson", container)
		.bindById("nexus-layout-editPerson-mobile", container);

	onClick
		.callback(menuCtrl.toggleMobileSubMenu)
		.bindByClassName("nexus-layout-mobile-menu-a-icon", container);

	onClick
		.callback(menuCtrl.closeMobileSubMenu)
		.bindByClassName("nexus-layout-mobile-menu-submenu-link", container);

	onClick
		.callback(menuCtrl.toggleMobileSubSubMenu)
		.bindByClassName("nexus-layout-mobile-admin-a-icon", container);

	onClick
		.callback(logout)
		.bindById("nexus-core-logout-btn", container);

	function storeClickedItem(event) {
		menuCtrl.storeClickedElement(professionalId, event, features);
	}

	function updateProfessionalSystemSettings() {
		menuCtrl.updateProfessionalSystemSettings(features);
	}
}

function bindSearchFormActions(menuSearch, container) {
	menuSearch.init();

	on("click")
		.callback((event) => menuSearch.onClickMobileSearchIcon(event))
		.bindByClassName("nexus-core-search-button", container);

	on("click")
		.callback((event) => menuSearch.submitSearchForm(event))
		.bindById("searchButton", container);

	on("submit")
		.callback((event) => menuSearch.submitSearchForm(event))
		.bindByClassName("navbar-form", container);
}

function noop() {}
