export const NAVIGATE = "NAVIGATE";
export const RELOAD = "RELOAD";
export const BACK_FORWARD = "BACK_FORWARD";
export const OTHER = "OTHER";

class NavigationType {
	constructor() {
		this.name = "Not Implemented!";
	}

	getType() {
		throw Error("NOT Implemented!");
	}

	isSupported() {
		throw Error("NOT Implemented!");
	}
}

class DefaultNavigationType extends NavigationType {
	constructor() {
		super();
		this.name = "DefaultNavigationType";
	}

	getType() {
		return RELOAD;
	}

	isSupported() {
		return true;
	}
}

class PerformanceNavigationTimingNavigationType extends NavigationType {
	constructor() {
		super();
		this.name = "PerformanceNavigationTimingNavigationType";
	}

	getType() {
		switch (performance.getEntriesByType("navigation")[0].type) {
			case "navigate":
				return NAVIGATE;
			case "reload":
				return RELOAD;
			case "back_forward":
				return BACK_FORWARD;
			default:
				return OTHER;
		}
	}

	isSupported() {
		return performance
			&& performance.getEntriesByType
			&& performance.getEntriesByType("navigation")
			&& performance.getEntriesByType("navigation").length > 0
			&& performance.getEntriesByType("navigation")[0].type;
	}
}

class PerformanceNavigationNavigationType extends NavigationType {
	constructor() {
		super();
		this.name = "PerformanceNavigationNavigationType";
	}

	getType() {
		switch (performance.navigation.type) {
			case 0:
				return NAVIGATE;
			case 1:
				return RELOAD;
			case 2:
				return BACK_FORWARD;
			default:
				return OTHER;
		}
	}

	isSupported() {
		return performance && performance.navigation && Number.isInteger(performance.navigation.type);
	}
}

const navigationTypes = [
	new PerformanceNavigationTimingNavigationType(),
	new PerformanceNavigationNavigationType(),
	new DefaultNavigationType()
];

const createNavigationType = () => {
	const navigationType = navigationTypes.find((element) => element.isSupported());
	// eslint-disable-next-line no-console
	console.info("Using navigation type: ", navigationType.name);
	return navigationType;
};

export default createNavigationType();
