import template from "lodash/template";
import translate from "../../../support/translate";
import htmlToElement from "../../../utils/htmlToElement";
import removeChildFromElement from "../../../utils/removeChildFromElement";
import schoolDataTemplate from "./school-data.tmpl.html";

const compiledTemplate = template(schoolDataTemplate);

function prepareParams(data) {
	return {
		schoolData: data,
		translate(key) {
			return translate.instant(key);
		}
	};
}

function schoolDataAdapter(data) {
	return {
		...data.schoolData,
		special: data.schoolData.specialClass
	};
}

export default class SchoolDataRenderer {

	render(data) {
		let schoolData;
		if (data.schoolData) {
			schoolData = schoolDataAdapter(data);
		} else if (data.school) {
			schoolData = data;
		} else {
			return;
		}
		this.remove();
		this.getSchoolDataContainers().forEach((container) => {
			const schoolDataElement = htmlToElement(compiledTemplate(prepareParams(schoolData)));
			container.appendChild(schoolDataElement);
		});
	}

	remove() {
		this.getSchoolDataContainers().forEach((element) => {
			removeChildFromElement(element);
		});
	}

	getSchoolDataContainers() {
		const containers = document.getElementsByClassName("school-data");
		if (containers) {
			return [...containers];
		}
		return [];
	}
}
