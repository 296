import on from "../../../utils/on";
import FlowerChartCtrl from "./flowerChartCtrl";

export default class FlowerChartActionBinder {
	constructor(modalService) {
		this.modalService = modalService;
	}

	bindActions(patientId) {
		const ctrl = new FlowerChartCtrl(patientId, this.modalService);
		bindClickActions(ctrl);
	}
}

function bindClickActions(ctrl) {
	const onClick = on("click");

	onClick
		.callback(() => ctrl.showFlowerChart())
		.bindByClassName("flower-chart-clickable-card");
}
