import template from "lodash/template";
import translate from "../../support/translate";
import htmlToElement from "../../utils/htmlToElement";
import hasLink from "../../utils/hasLink";
import getLink from "../../utils/getLink";
import preparePatientStateHistory from "../utils/preparePatientStateHistory";
import nexusLayoutPatientCardsTemplate from "./nexus-layout-patient-cards.tmpl.html";

const compiledPatientCards = template(nexusLayoutPatientCardsTemplate);

function prepareParams(patient, patientOverview, patientOverviewForms, patientChangeEnabled, prefix) {
	return {
		patient,
		patientOverview,
		patientOverviewForms,
		translate(key) {
			return translate.instant(key);
		},
		getColor,
		isBiohazard,
		isCustodian,
		hasTheSameAddress,
		isUnderCustody,
		getMunicipalityCurrentValue,
		patientChangeEnabled,
		currentAddress: getCurrentAddress(patient),
		prefix, // prefix is added to all ids in template, because the html elements can be rendered multiple times in one document, e.g. patient modal opened on citizen overview
		hasLink,
		sapaLink: prepareSapaLink(patient),
		patientStateHistory: preparePatientStateHistory(patient, 6, new Date())
	};
}

export default class PatientCardsTemplateRenderer {
	constructor(container) {
		this.container = container;
	}

	render(patient, params, patientOverview, patientOverviewForms, patientChangeEnabled) {
		const rootElement = this.getRootElement(params);
		if (rootElement) {
			const patientOverviewElement = htmlToElement(compiledPatientCards(prepareParams(patient, patientOverview,
				patientOverviewForms, patientChangeEnabled, params.prefix)));
			rootElement.appendChild(patientOverviewElement);

			if (hasImportantCards(patientOverviewForms)) {
				const elements = [...document.getElementsByClassName("toggle-cards-open"), ...document.getElementsByClassName("toggle-cards-close")];
				elements.forEach((element) => {
					element.classList.add("important-cards");
				});
			}
		}
	}

	getRootElement(params) {
		if (this.container) {
			return this.container.querySelector(`[id='${params.prefix}nexus-layout-patient-cards-main']`);
		}
		return document.getElementById(`${params.prefix}nexus-layout-patient-cards-main`);
	}
}

function prepareSapaLink(patient) {
	return hasLink(patient, "sapa") ? getLink(patient, "sapa") : undefined;
}

function getColor(formData) {
	if (formData.formDefinition.formSignificance === "WARNING") {
		return "#F3DF81";
	}
	if (formData.marker && formData.marker.color) {
		return `#${formData.marker.color}`;
	}
	return undefined;
}

function isBiohazard(form) {
	if (form.marker) {
		const bioHazard = form.marker.icon;
		if (bioHazard && bioHazard === "BIOHAZARD") {
			return true;
		}
	}
	return false;
}

function isCustodian(contact, patientOverview) {
	if (patientOverview.custodianContacts) {
		const custodian = patientOverview.custodianContacts.find((custodianContact) => custodianContact.id === contact.id);
		return custodian !== undefined;
	}
	return false;
}

function hasTheSameAddress(contact, patientOverview) {
	if (patientOverview.sameAddressContacts) {
		const result = patientOverview.sameAddressContacts.find((sameAddressContact) => sameAddressContact.id === contact.id);
		return result !== undefined;
	}
	return false;
}

function isUnderCustody(contact, patientOverview) {
	if (patientOverview.underCustodyContacts) {
		const underCustody = patientOverview.underCustodyContacts.find((underCustodyContact) => underCustodyContact.id === contact.id);
		return underCustody !== undefined;
	}
	return false;
}

function getCurrentAddress(patient) {
	switch (patient.currentAddressIndicator) {
		case "PRIMARY_ADDRESS":
			return patient.primaryAddress;
		case "SECONDARY_ADDRESS":
			return patient.secondaryAddress;
		case "SUPPLEMENTARY_ADDRESS":
			return patient.supplementaryAddress;
		case "TEMPORARY_ADDRESS":
			return patient.temporaryAddress;
		default:
			return undefined;
	}
}

function hasImportantCards(patientOverviewForms) {
	const warningForm = patientOverviewForms.find((form) => form.formDefinition.formSignificance === "WARNING");

	if (warningForm) {
		return true;
	}
	return warningForm;
}

function getMunicipalityCurrentValue(municipalityValueSchedule) {
	let returnValue = "";
	if (municipalityValueSchedule && municipalityValueSchedule.currentValue) {
		returnValue += municipalityValueSchedule.currentValue.name;
		if (municipalityValueSchedule.currentValue.municipalityCode) {
			returnValue += ` (${municipalityValueSchedule.currentValue.municipalityCode})`;
		}
	}
	return returnValue;
}
