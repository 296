const cachedScreenHeight = {};

function height() {
	if (!(cachedScreenHeight.timestamp) || (Date.now() > cachedScreenHeight.timestamp + 1000)) {
		cachedScreenHeight.height = document.getElementsByClassName("bswd")[0].offsetHeight;
		cachedScreenHeight.timestamp = Date.now();
	}
	return cachedScreenHeight.height;
}

// xs = 1, sm = 2, md = 3, lg = 4
function isXSmallScreen() {
	return height() < 2;
}

function isSmallScreen() {
	return height() < 3;
}

function isNotSmallScreen() {
	return height() >= 3;
}

function isNotXSmallScreen() {
	return height() > 1;
}

const touchDevice = isTouchDevice();

function isTouchDevice() {
	try {
		document.createEvent("TouchEvent");

		const isChromium = window.chrome !== undefined;
		const isWindows10 = window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1;

		return true && !(isChromium && isWindows10);
	} catch (e) {
		return false;
	}
}

function windowHeight() {
	return window.innerHeight;
}

function windowWidth() {
	return window.innerWidth;
}

function headerHeightTo(headerSelector) {
	const pageHeader = document.getElementsByClassName(headerSelector)[0];
	if (pageHeader.length) {
		const pageHeaderHeight = pageHeader.offsetHeight;
		const rect = pageHeader.getBoundingClientRect();
		const pageHeaderTop = rect.top + document.body.scrollTop;
		return pageHeaderHeight + pageHeaderTop;
	}
	return 0;
}

function pageHeightBelowPageHeader() {
	return pageHeightBelow("page-header");
}

function pageHeightBelowTopBar() {
	return pageHeightBelow("nlc-topbar");
}

function pageHeightBelow(selector) {
	return windowHeight() - headerHeightTo(selector);
}

function leftMenuWidth() {
	// eslint-disable-next-line no-undef
	const menu = $(".menu-desktop ul");
	return menu.length ? menu.width() : 0;
}

function pageWidth() {
	return windowWidth() - leftMenuWidth();
}

const isIe = isIeBrowser();

function isIeBrowser() {
	const ieRegexp = /Trident/i;

	return ieRegexp.test(navigator.userAgent);
}

const isIEorEdge = isIEorEdgeBrowser();

function isIEorEdgeBrowser() {
	const myNav = navigator.userAgent.toLowerCase();
	return (myNav.indexOf("msie") !== -1) || (myNav.indexOf("edge") !== -1 || (myNav.indexOf("trident") !== -1));
}

const TOUCH_DEVICE_MODE_IDLE_TIME = 2000;	// 2s
let lastTouchTimestamp;

(function startTouchDeviceModeTracking() {
	window.addEventListener("touchstart", () => {
		lastTouchTimestamp = new Date().getTime();
	});
}());

function isDeviceCurrentlyWorkingInTouchMode() {
	return lastTouchTimestamp ? (new Date().getTime() - lastTouchTimestamp < TOUCH_DEVICE_MODE_IDLE_TIME) : false;
}

export default {
	isXSmallScreen,
	isSmallScreen,
	isNotSmallScreen,
	isNotXSmallScreen,
	isMediumOrLargerScreen: isNotSmallScreen,
	windowHeight,
	windowWidth,
	pageHeightBelowPageHeader,
	pageHeightBelowTopBar,
	pageHeightBelow,
	pageWidth,
	isTouchDevice() {
		return touchDevice;
	},
	isDeviceCurrentlyWorkingInTouchMode() {
		return isDeviceCurrentlyWorkingInTouchMode();
	},
	isIe() {
		return isIe;
	},
	isIEorEdge() {
		return isIEorEdge;
	}
};
