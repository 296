import { PATIENT_MENU_CONTEXT } from "../../menuBuilder/menuType";
import storageAccess from "../../utils/recentlyUsedItemStorage";

function RecentItemsPatientMenu(patientMenuData) {
	const vm = this;
	const storage = storageAccess(PATIENT_MENU_CONTEXT);
	const submenuLengthRequirement = 10;
	const recentItemLength = 3;

	vm.storeClickedElement = storeClickedElement;
	vm.initRecentItems = initRecentItems;

	function storeClickedElement(professionalId, contextMenuData, childItem) {
		if (contextMenuData.submenu.length > submenuLengthRequirement && professionalId) {
			storageAccess(PATIENT_MENU_CONTEXT).setValue(professionalId, contextMenuData.title, childItem.text);
			updateRecentItemsForMenu(professionalId, contextMenuData);
		}
	}

	function updateRecentItemsForMenu(professionalId, clickedContextMenuData) {
		const contextMenus = Array.from(document.getElementsByClassName("nexus-layout-patient-menu-subnavelm"));
		contextMenus.forEach((contextMenu) => {
			if (clickedContextMenuData.title === contextMenu.getElementsByClassName("nexus-layout-patient-menu-element")[0].text) {
				const stored = storageAccess(PATIENT_MENU_CONTEXT).getValue(professionalId, clickedContextMenuData.title);
				const placeholders = contextMenu.getElementsByClassName("placeholder");
				enableMenuDivider(contextMenu);
				updateRecentItemPlaceholders(contextMenu, stored, placeholders, clickedContextMenuData);
			}
		});
	}

	function initRecentItems(professionalId) {
		if (!professionalId) {
			return;
		}
		storage.cleanStorageEntryForNewProfessional(professionalId);
		const contextMenus = Array.from(document.getElementsByClassName("nexus-layout-patient-menu-subnavelm"));
		contextMenus.forEach((contextMenu) => {
			const menuName = contextMenu.getElementsByClassName("nexus-layout-patient-menu-element")[0].text;
			const contextMenuData = patientMenuData.filter((item) => item.title === menuName)[0];
			const stored = storageAccess(PATIENT_MENU_CONTEXT).getValue(professionalId, menuName);
			const placeholders = contextMenu.getElementsByClassName("placeholder");

			if (placeholders && placeholders.length > 0 && stored && contextMenuData) {
				updateRecentItemPlaceholders(contextMenu, stored, placeholders, contextMenuData);
			}
		});
	}

	function updateRecentItemPlaceholders(contextMenu, stored, placeholders, clickedContextMenuData) {
		enableMenuDivider(contextMenu);
		for (let i = 0; i < recentItemLength; i += 1) {
			if (stored && stored[i]) {
				const itemDataToClone = clickedContextMenuData.submenu.filter((item) => item.title === stored[i]);
				if (itemDataToClone && itemDataToClone.length > 0) {
					fillPlaceholderWithMenuItem(itemDataToClone[0], placeholders[i]);
				}
			}
		}
	}

	function fillPlaceholderWithMenuItem(itemDataToClone, placeholder) {
		const linkElement = placeholder.getElementsByClassName("nexus-layout-patient-menu-submenu-data-link")[0];
		const placeholderElement = placeholder;
		linkElement.setAttribute("href", itemDataToClone.url);
		placeholderElement.classList.remove("hidden-element");
		linkElement.text = itemDataToClone.title;
	}

	function enableMenuDivider(contextMenu) {
		const menuDivider = contextMenu.getElementsByClassName("menuDivider")[0];
		if (menuDivider.classList.contains("hidden-element")) {
			menuDivider.classList.remove("hidden-element");
		}
	}
}

export default RecentItemsPatientMenu;
