const activeClass = "active";

const containsClass = (element, className) => (element.classList.contains(className));

function findParentElementWithClass(element, className) {
	if (containsClass(element, className)) {
		return element;
	}
	return findParentElementWithClass(element.parentNode, className);
}

function getElementById(id) {
	return document.getElementById(id);
}

function getElementsByClassName(className) {
	return [...document.getElementsByClassName(className)];
}
function addClass(id, className) {
	const element = getElementById(id);
	if (element) {
		element.classList.add(className);
	}
}

function removeClass(id, className) {
	const element = getElementById(id);
	if (element) {
		element.classList.remove(className);
	}
}

export default {
	toggleMenu() {
		document.getElementsByTagName("body")[0].classList.toggle("menu-toggled");
	},
	closeMenu() {
		document.getElementsByTagName("body")[0].classList.remove("menu-toggled");
	},

	closeMainMenu() {
		const menuMainElements = [...document.getElementsByClassName("nexus-layout-menu-main")];
		menuMainElements.forEach((element) => {
			element.classList.remove("active");
		});
	},
	openMainMenu(element) {
		this.closeMenu();
		findParentElementWithClass(element, "nexus-layout-menu-main").classList.add("active");
	},
	openAdminMenu() {
		addClass("nexus-layout-top-menu-admin-menu-li", activeClass);
		addClass("nexus-layout-top-menu-admin-menu-ul", activeClass);
	},
	closeAdminMenu() {
		removeClass("nexus-layout-top-menu-admin-menu-li", activeClass);
		removeClass("nexus-layout-top-menu-admin-menu-ul", activeClass);
	},
	openAccountMenu() {
		addClass("professionalTopBarSettings", activeClass);
		addClass("nexus-layout-professionalTopBarSettings-ul", activeClass);
	},
	closeAccountMenu() {
		removeClass("professionalTopBarSettings", activeClass);
		removeClass("nexus-layout-professionalTopBarSettings-ul", activeClass);
	},
	toggleMobileSubMenu(element) {
		const parentElement = findParentElementWithClass(element, "nexus-layout-menu-main-mobile");
		const currentIsActive = containsClass(parentElement, activeClass);

		const mobileMenuElement = document.getElementsByClassName("menu-mobile");
		if (mobileMenuElement) {
			const activeElements = [...mobileMenuElement[0].getElementsByClassName(activeClass)];
			activeElements.forEach((activeElement) => activeElement.classList.remove(activeClass));
		}

		if (!currentIsActive) {
			parentElement.classList.add(activeClass);
			const aIconElement = parentElement.getElementsByClassName("nexus-layout-mobile-menu-a-icon")[0];
			aIconElement.classList.add(activeClass);
		}
	},
	toggleMobileSubSubMenu(element) {
		const parentElement = findParentElementWithClass(element, "nexus-layout-mobile-admin-a-icon");
		const currentIsActive = containsClass(parentElement, activeClass);

		const mobileAdminElements = getElementsByClassName("nexus-layout-mobile-admin-a-icon");
		if (mobileAdminElements) {
			mobileAdminElements.forEach((mobileAdminElement) => { mobileAdminElement.classList.remove(activeClass); });
		}

		if (!currentIsActive) {
			parentElement.classList.add(activeClass);
		}
	},
	closeMobileSubMenu(element) {
		const parentElement = findParentElementWithClass(element, "nexus-layout-menu-main-mobile");

		if (parentElement && containsClass(parentElement, activeClass)) {
			parentElement.classList.remove(activeClass);
			const aIconElement = parentElement.getElementsByClassName("nexus-layout-mobile-menu-a-icon")[0];
			aIconElement.classList.remove(activeClass);
		}
	}
};
