import viewSpec from "../../utils/viewSpec";
import RecentItemsMenu from "./recentItemsMenu";

function siblings(el) {
	return Array.prototype.filter.call(el.parentNode.children, (child) => (child !== el));
}

function MenuCtrl(toggleMenuCtrl, eventBus, location, professionalDialogsUrls) {
	const vm = this;
	const recentItemMenu = new RecentItemsMenu();
	vm.iconTimeoutHandle = null;

	vm.toggleMenuCtrl = toggleMenuCtrl;

	vm.toggleMenu = toggleMenu;
	vm.hideMenu = hideMenu;

	vm.adminMenuMouseOver = adminMenuMouseOver;
	vm.adminMenuToggle = adminMenuToggle;
	vm.adminMenuClose = adminMenuClose;

	vm.accountMenuMouseOver = accountMenuMouseOver;
	vm.accountMenuToggle = accountMenuToggle;
	vm.accountMenuClose = accountMenuClose;
	vm.accountMenuActive = false;

	vm.toggleMobileSubMenu = toggleMobileSubMenu;
	vm.toggleMobileSubSubMenu = toggleMobileSubSubMenu;
	vm.closeMobileSubMenu = closeMobileSubMenu;

	vm.mouseOver = mouseOver;
	vm.mouseLeave = mouseLeave;

	vm.updateProfessional = updateProfessional;
	vm.updateProfessionalSystemSettings = updateProfessionalSystemSettings;
	vm.editPreferences = editPreferences;
	vm.editPerson = editPerson;

	vm.iconMouseEnter = iconMouseEnter;
	vm.iconMouseLeave = iconMouseLeave;
	vm.storeClickedElement = storeClickedElement;
	vm.initRecentItemsForMenu = initRecentItemsForMenu;

	function storeClickedElement(professionalId, event, features) {
		if (features && features["ui.showRecentMenuItems"]) {
			recentItemMenu.storeClickedElement(professionalId, event);
		}
	}

	function initRecentItemsForMenu(professionalId, features) {
		if (features && features["ui.showRecentMenuItems"]) {
			recentItemMenu.initRecentItemsForMenu(professionalId);
		}
	}

	/* icon mouseover simulates header hover to underline */
	function iconMouseEnter($event) {
		const siblingElements = siblings($event.target);
		const subHeaderSiblings = siblingElements.filter((sibling) => sibling.classList.contains("subheader-url"));
		subHeaderSiblings.forEach((subHeaderSibling) => subHeaderSibling.classList.add("hover"));
	}

	function iconMouseLeave($event) {
		const siblingElements = siblings($event.target);
		const subHeaderSiblings = siblingElements.filter((sibling) => sibling.classList.contains("subheader-url"));
		subHeaderSiblings.forEach((subHeaderSibling) => subHeaderSibling.classList.remove("hover"));
	}

	function toggleMenu() {
		vm.toggleMenuCtrl.toggleMenu();
		eventBus.broadcast("toggleMenuEvent");
	}

	function hideMenu() {
		vm.toggleMenuCtrl.closeMenu();
		menuClose();
	}

	function closeMobileSubMenu($event) {
		vm.toggleMenuCtrl.closeMobileSubMenu($event.target);
	}

	function toggleMobileSubMenu($event) {
		vm.toggleMenuCtrl.toggleMobileSubMenu($event.target);
	}

	function toggleMobileSubSubMenu($event) {
		vm.toggleMenuCtrl.toggleMobileSubSubMenu($event.target);
	}

	function setIconTimeout(callback) {
		clearIconTimeout();
		// eslint-disable-next-line @typescript-eslint/no-implied-eval
		vm.iconTimeoutHandle = window.setTimeout(callback, 300);
	}

	function clearIconTimeout() {
		if (vm.iconTimeoutHandle == null) {
			return;
		}

		window.clearTimeout(vm.iconTimeoutHandle);
		vm.iconTimeoutHandle = null;
	}

	function mouseOver(event) {
		setIconTimeout(() => {
			menuOpen(event.target);
		});
	}

	function mouseLeave() {
		clearIconTimeout();
		menuClose();
	}

	function menuOpen(element) {
		toggleMenuCtrl.openMainMenu(element);

		const windowHeight = viewSpec.windowHeight();
		const subMenuElms = [...document.querySelectorAll(".menu-desktop > ul ul")];

		if (viewSpec.isNotXSmallScreen()) {
			subMenuElms.forEach((subMenuElm) => {
				// var $this = $(subMenuElm);

				// var elmHeight = $this.height();
				const elmHeight = subMenuElm.clientHeight;
				// console.log("elmHeight", elmHeight, subMenuElm.offsetHeight, subMenuElm.clientHeight, subMenuElm.scrollHeight);
				// subMenuElm.parentNode.offsetTop
				// var elmPositionTop = $this.parent("li").position().top;
				const elmPositionTop = subMenuElm.parentNode.offsetTop;
				// console.log("elmPositionTop", elmPositionTop, subMenuElm.parentNode.offsetTop);

				const newElmHeight = (windowHeight - elmPositionTop) - 66;
				let areaHeight = elmHeight;

				if (elmHeight >= newElmHeight) {
					// eslint-disable-next-line no-param-reassign
					subMenuElm.style.height = `${newElmHeight}px`;
					// $this.css("height", newElmHeight + "px");
					areaHeight = newElmHeight;
				}
				// eslint-disable-next-line no-param-reassign
				subMenuElm.parentNode.querySelector(".transparent-area").style.height = `${areaHeight}px`;
				// $($this).siblings(".transparent-area").height(areaHeight + "px");
			});
		}
	}

	function menuClose() {
		toggleMenuCtrl.closeMainMenu();

		const subMenuElms = [...document.querySelectorAll(".menu-desktop > ul ul")];

		subMenuElms.forEach((element) => {
			// eslint-disable-next-line no-param-reassign
			element.style.height = "auto";
		});
	}

	function adminMenuToggle() {
		if (vm.adminMenuActive) {
			adminMenuClose();
		} else {
			adminMenuShow();
		}
	}

	function adminMenuMouseOver() {
		if (vm.accountMenuActive) {
			vm.accountMenuActive = false;
			vm.toggleMenuCtrl.closeAccountMenu();

			vm.adminMenuActive = true;
			vm.toggleMenuCtrl.openAdminMenu();
		}
	}

	function accountMenuMouseOver() {
		if (vm.adminMenuActive) {
			vm.adminMenuActive = false;
			vm.toggleMenuCtrl.closeAdminMenu();

			vm.accountMenuActive = true;
			vm.toggleMenuCtrl.openAccountMenu();
		}
	}

	function adminMenuClose() {
		vm.adminMenuActive = false;
		toggleMenuCtrl.closeAdminMenu();
		detachCloseEvent();
	}

	function adminMenuShow() {
		accountMenuClose();
		vm.adminMenuActive = true;
		toggleMenuCtrl.openAdminMenu();
		attachCloseEvent();
	}

	function accountMenuToggle(event) {
		event.preventDefault();
		adminMenuClose();
		if (vm.accountMenuActive) {
			accountMenuClose();
		} else {
			accountMenuShow();
		}
	}

	function accountMenuShow() {
		vm.accountMenuActive = true;
		vm.toggleMenuCtrl.openAccountMenu();
		attachCloseEvent();
	}

	function accountMenuClose() {
		vm.accountMenuActive = false;
		vm.toggleMenuCtrl.closeAccountMenu();
		detachCloseEvent();
	}

	// todo move to binder?
	function attachCloseEvent() {
		document.addEventListener("click", closeTopMenu);
	}

	function detachCloseEvent() {
		document.removeEventListener("click", closeTopMenu);
	}

	function closeTopMenu(evt) {
		const topMenuElement = document.getElementsByClassName("user-menu");
		if (topMenuElement && topMenuElement[0].contains(evt.target)) {
			return;
		}
		accountMenuClose();
		adminMenuClose();
	}

	function returnUrl() {
		return location.getFullPath();
	}
	function editPreferences() {
		const editPreferencesUrl = professionalDialogsUrls.editPreferences(returnUrl());
		location.path(editPreferencesUrl.url, editPreferencesUrl.params);
	}

	function updateProfessional() {
		const updateProfessionalUrl = professionalDialogsUrls.updateProfessional((returnUrl()));
		location.path(updateProfessionalUrl.url, updateProfessionalUrl.params);
	}

	function updateProfessionalSystemSettings(features) {
		if (features && features["ui.profiles"]) {
			const editProfileUrl = professionalDialogsUrls.editProfile().url;
			location.path(editProfileUrl);
		} else {
			const updateProfessionalSystemSettingsUrl = professionalDialogsUrls.updateProfessionalSystemSettings((returnUrl()));
			location.path(updateProfessionalSystemSettingsUrl.url, updateProfessionalSystemSettingsUrl.params);
		}
	}

	function editPerson() {
		const editPersonUrl = professionalDialogsUrls.editPerson().url;
		location.path(editPersonUrl);
	}
}
export default MenuCtrl;
