import template from "lodash/template";
import htmlToElement from "../../utils/htmlToElement";
import nexusLayoutTemplate from "./nexus-layout-template.tmpl.html";
import flexTemplate from "./flex-layout-template.tmpl.html";

const compiledNexusLayout = template(nexusLayoutTemplate);
const compiledFlexLayout = template(flexTemplate);

export default class BaseTemplateRenderer {
	constructor(container, useFlexLayout = false) {
		this.container = container;
		this.useFlexLayout = useFlexLayout;
	}

	render(params) {
		const container = this.container ? this.container : document.getElementById(params.nexusLayoutElementId);

		const nexusElement = htmlToElement(this.useFlexLayout ? compiledFlexLayout() : compiledNexusLayout());
		const applicationElements = [...container.children];
		if (applicationElements && applicationElements.length > 0) {
			const firstApplicationContent = applicationElements[0];
			container.insertBefore(nexusElement, firstApplicationContent);
			const applicationBody = container.querySelector("#nexus-layout-application-body");
			applicationElements.forEach((applicationElement) => applicationBody.appendChild(applicationElement));
		} else {
			container.appendChild(nexusElement);
		}
	}
}
