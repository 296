export default {
	layout_logout: "Log ud",
	layout_menu: "Menu",
	layout_search: "S\u00F8g",
	layout_administration: "Administration",
	layout_profile: "Profil",
	layout_profile_system_settings: "Systemindstillinger",
	layout_myProfile: "Min profil",
	layout_user: "Bruger",
	layout_preferences: "Pr\u00e6ferencer",
	layout_person: "Personlige oplysninger",
	patientOverview_basicData_header: "Stamdata",
	patientOverview_patientCondition_header: "Tilstande",

	patientOverview_identifier_CPR: "CPR",
	patientOverview_identifier_ANONYMOUS: "Person ID",

	patientOverview_age: "\u00E5r",
	patientOverview_ageMonths: "mdr.",

	patient_patientState_label: "Borgerstatus",
	no_state_changes_in_6_months: "Der har ikke v\u00E6ret \u00E6ndringer det sidste halve \u00E5r",

	patientOverview_sapaLink_header: "Link til SAPA",
	patientOverview_sapaLink_label: "\u00C5bn borger",

	patientOverview_known_as: "Kaldenavn",

	patient_maritalStatus_label: "Civilstand",
	patient_maritalStatus_NOT_INFORMED: "Ikke oplyst",
	patient_maritalStatus_MARRIED: "Gift",
	patient_maritalStatus_UNMARRIED: "Ugift",
	patient_maritalStatus_COHABITING: "Registreret partnerskab",
	patient_maritalStatus_SEPARATED: "Oph\u00E6vet partnerskab",
	patient_maritalStatus_DIVORCED: "Fraskilt",
	patient_maritalStatus_WIDOW: "Enke/-mand",
	mainMenu_createPreference: "[Ingen visninger]",

	patientOverview_patientCondition_empty: "Der er endnu ingen tilstande p\u00E5 borger",
	patientOverview_address_indicator_PRIMARY_ADDRESS: "Folkeregisteradresse 1",
	patientOverview_address_indicator_SECONDARY_ADDRESS: "Folkeregisteradresse 2",
	patientOverview_address_indicator_SUPPLEMENTARY_ADDRESS: "Supplerende adresse",
	patientOverview_address_indicator_TEMPORARY_ADDRESS: "Midlertidig adresse",
	patientOverview_custodian: "For\u00E6ldremynd.",

	nexus_video_start_video_call: "Start videokald",
	nexus_video__status_error: "Ingen forbindelse",

	school_data_error: "Der er opst\u00E5et en ukendt fejl",
	school_data_not_found: "Ingen data fundet",
	school_special_class: "specialklasse",

	patient_averageVisitedTime_header: "Gns. visiterede timer pr. uge",
	patient_averageVisitedTime_calculate: "Beregn",

	patientOverview_paragraphGroup_REHABILITATION_SERVICELOV: "Genopt. SEL",
	patientOverview_paragraphGroup_REHABILITATION_SUNDHEDSLOV: "Genopt. SUL",
	patientOverview_paragraphGroup_REHABILITATION2_SERVICELOV: "Rehabilitering",
	patientOverview_paragraphGroup_PRACTICAL_HELP: "Praktisk hj\u00E6lp",
	patientOverview_paragraphGroup_PERSONAL_CARE: "Personlig pleje",
	patientOverview_paragraphGroup_NURSING: "Sygepleje",
	patientOverview_paragraphGroup_HOUSING_SUPPORT: "Bost\u00F8tte",

	patient_actingMunicipality_label: "Handlekommune",
	patient_payingMunicipality_label: "Betalingskommune",

	error_popup_btnClose: "Luk",
	error_popup_title: "Fejl",
	error_popup_maintenance_title: "Systemet er ikke tilg\u00e6ngeligt",
	error_status_401: "Der opstod en fejl ved verificering af brugeren. \u00C5rsagen kan v\u00E6re:\n\n"
		+ "- at brugeren ikke har de n\u00F8dvendige rettigheder\n- at brugeren er inaktiv\n"
		+ "- at brugerens login-session er udl\u00F8bet. Er brugeren lige logget p\u00E5 Nexus kan \u00E5rsagen v\u00E6re,\n"
		+ " at tidsindstillingen p\u00E5 computer eller tablet ikke stemmer overens med tidsindstillingen i Nexus\n\n"
		+ " Kontakt systemadministrator for at f\u00E5 hj\u00E6lp.",
	error_status_403: "Brugeren har ikke rettigheder til at benytte systemet.",
	error_status_409: "Aktiviteten er i mellemtiden blevet opdateret af en anden bruger.",
	error_status_500: "Der opstod en uventet fejl. Kontakt din systemadministrator",
	error_status_503: "Siden er under opdatering, og er derfor ikke tilg\u00e6ngelig. Pr\u00f8v venligst senere",
	error_status_0: "Der er opst\u00E5et en fejl i forbindelsen til API serveren. Pr\u00F8v igen senere."
};
