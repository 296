export default function calculatePatientAge(patientBirthDate, calculationDate) {
	const patientAge = {
		years: null,
		months: null
	};
	if (!patientBirthDate || !calculationDate) {
		return patientAge;
	}
	const patientBirthDateForParsingFormat = patientBirthDate.replace(".000+0000", " GMT").replace(/-/g, "/").replace("T", " ");
	const birthDate = new Date(patientBirthDateForParsingFormat);
	let ageInMonths = ((calculationDate.getFullYear() - birthDate.getFullYear()) * 12) - 1;
	const calculationUTCDate = calculationDate.getDate();
	const birthDayUTCDate = birthDate.getDate();
	const nowDateMonths = calculationDate.getMonth();
	const birthDateMonths = birthDate.getMonth();
	if (nowDateMonths > birthDateMonths) {
		ageInMonths += (nowDateMonths - birthDateMonths);
	}
	if (nowDateMonths < birthDateMonths) {
		ageInMonths -= (birthDateMonths - nowDateMonths);
	}
	if (calculationUTCDate >= birthDayUTCDate) {
		ageInMonths += 1;
	}
	const ageInYears = Math.floor(ageInMonths / 12);
	if (ageInYears >= 18) {
		patientAge.years = ageInYears;
	} else {
		patientAge.years = ageInYears;
		patientAge.months = ageInMonths % 12;
	}
	return patientAge;
}
