import getLink from "../../../utils/getLink";

export default class NexusVideoCtrl {
	constructor(linkHolder) {
		this.linkHolder = linkHolder;
	}

	initCall() {
		const newWindow = window.open("", "_blank");
		const url = getLink(this.linkHolder, "videoCallConference");
		newWindow.location = url;
	}
}
