import translate from "../support/translate";

export default class PatientMenuDataBuilder {
	constructor(menuBuilder) {
		this.menuBuilder = menuBuilder;
	}

	buildPatientData(patient, preference, params) {
		return this.menuBuilder.buildPatient(patient, preference, true, translate, params);
	}
}
