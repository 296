import on from "../../utils/on";
import ClickLink from "../../support/clickLink";
import PatientLayoutCtrl from "./patientLayoutCtrl";

export default class PatientMenuActionBinder {
	constructor(features) {
		this.features = features;
		this.patientLayoutCtrl = null;
	}

	bindActions(patientMenuData, location, professionalId) {
		this.patientLayoutCtrl = new PatientLayoutCtrl(patientMenuData, location, professionalId, this.features);
		bindMouseEnterActions(this.patientLayoutCtrl);
		bindMouseLeaveActions(this.patientLayoutCtrl);
		this.patientLayoutCtrl.setSubnavHeight();
		window.addEventListener("resize", () => {
			this.patientLayoutCtrl.setSubnavHeight();
		});
		bindClickOnLinks(new ClickLink(location));
		bindClickActions(this.patientLayoutCtrl, patientMenuData);
	}

	pageChanged() {
		if (this.patientLayoutCtrl) {
			this.patientLayoutCtrl.initSubMenuActive(false);
		}
	}
}

function bindClickOnLinks(clickLink) {
	on("click")
		.callback((e) => clickLink.handle(e))
		.bindById("nexus-layout-patient-menu-subnav");
}

function bindMouseEnterActions(patientLayoutCtrl) {
	const onMouseEnter = on("mouseenter");

	onMouseEnter
		.callback(patientLayoutCtrl.mouseEnterSubMenu)
		.bindByClassName("nexus-layout-patient-menu-subnavelm");
}

function bindMouseLeaveActions(patientLayoutCtrl) {
	const onMouseLeave = on("mouseleave");

	onMouseLeave
		.callback(patientLayoutCtrl.mouseLeaveSubMenu)
		.bindByClassName("nexus-layout-patient-menu-subnavelm");
}

function bindClickActions(patientLayoutCtrl, patientMenuData) {
	const onClick = on("click");

	onClick
		.callback(toggleSubMenu)
		.bindByClassName("nexus-layout-patient-menu-element");

	onClick
		.callback(patientLayoutCtrl.toggleSubnavVisibility)
		.bindById("nexus-layout-patient-menu-subnav-toggle");

	onClick
		.callback(selectSubMenuItem)
		.bindByClassName("nexus-layout-patient-menu-submenu-data-link");

	onClick
		.callback(storeClicked)
		.bindByClassName("nexus-layout-patient-menu-submenu-data-link");

	function storeClicked(event) {
		patientLayoutCtrl.storeClickedElement(getMenuDataForElement(event.target), event.target);
	}

	function toggleSubMenu(event) {
		return patientLayoutCtrl.toggleSubMenu(getMenuDataForElement(event.target), getMenuDataIndex(event.target), event);
	}

	function selectSubMenuItem(event) {
		patientLayoutCtrl.selectSubMenuItem(getMenuDataIndex(event.target));
	}

	function getMenuDataForElement(element) {
		const menuDataIndex = getMenuDataIndex(element);
		if (typeof menuDataIndex === "number") {
			return patientMenuData[menuDataIndex];
		}
		return undefined;
	}

	function getMenuDataIndex(element) {
		const classes = element.classList;
		for (let i = 0; i < classes.length; i += 1) {
			const className = classes.item(i);
			if (className.startsWith("nexus-layout-element-")) {
				return parseInt(className.substr(21), 10);
			}
		}
		return undefined;
	}
}
