import contentHeight from "../../utils/contentHeight";
import AverageVisitedTimeTableRenderer from "./averageVisitedTimeTableRenderer";

export default class AverageVisitedTimeCtrl {
	constructor(apiAccess, patient, prefix, errorPopup) {
		this.patient = patient;
		this.prefix = prefix;
		this.apiAccess = apiAccess;
		this.averageVisitedTimeTableRenderer = new AverageVisitedTimeTableRenderer();
		this.errorPopup = errorPopup;
	}

	showAverageVisitedTime() {
		this.apiAccess.using(this.patient).get("averageVisitedTimeLink", {})
			.then(
				(averageVisitedTime) => {
					const containerId = `${this.prefix}nexus-layout-patient-average-visited-time-content`;
					this.averageVisitedTimeTableRenderer.remove(containerId);
					this.averageVisitedTimeTableRenderer.render(containerId, averageVisitedTime);
					contentHeight.calculate();
				},
				(reason) => {
					this.errorPopup.handleError(reason);
				}
			);
	}
}
