function isFunction(object) {
	return !!(object && object.constructor && object.call && object.apply);
}

function wrapFeatures(features) {
	return {
		isEnabled(featureName) {
			return features[featureName] === true;
		}
	};
}

export default function renderConditionChecker(features) {
	if (!features) {
		// eslint-disable-next-line no-console
		console.warn("Features flags are not provided. Please check your configuration");
	}

	const featureFlags = wrapFeatures(features || {});

	return {
		check(conditionFn) {
			if (conditionFn === undefined || conditionFn === null) {
				return true;
			}
			if (!isFunction(conditionFn)) {
				throw Error("'renderCondition' parameter is not a function.");
			}

			const result = conditionFn(featureFlags);
			return result === true;
		}
	};
}
