export default class ToggleSideBar {
	constructor(element) {
		this.element = element;
	}

	toggleSideBar() {
		const container = this.element.parentNode;
		container.classList.toggle("cards-active");
	}

	showSideBar() {
		const container = this.element.parentNode;
		container.classList.add("cards-active");
	}
}
