import MenuBuilder from "../menuBuilder/menuBuilder";
import PatientMenuDataBuilder from "../menuBuilder/patientMenuDataBuilder";
import Location from "../support/location";
import ErrorPopup from "../errorHandling/errorPopup";
import pageWasRefreshed from "../utils/pageWasRefreshed";
import hasLink from "../utils/hasLink";
import BasePatientLayoutTemplateRenderer from "./base/basePatientLayoutTemplateRenderer";
import PatientOverviewTemplateRenderer from "./overview/patientOverviewTemplateRenderer";
import PatientCardsRenderer from "./overview/patientCardsTemplateRenderer";
import FlowerChartRenderer from "./overview/flowerChart/flowerChartRenderer";
import NexusVideoRenderer from "./overview/nexusVideo/nexusVideoRenderer";
import SchoolDataRenderer from "./overview/schoolData/schoolDataRenderer";
import PatientCardsActionBinder from "./overview/patientCardsActionBinder";
import PatientOverviewActionBinder from "./overview/patientOverviewActionBinder";
import PatientMenuTemplateRenderer from "./menu/patientMenuTemplateRenderer";
import PatientMenuActionBinder from "./menu/patientMenuActionBinder";
import PatientDefaultPage from "./defaultPage/patientDefaultPage";
import PatientPageDetection from "./utils/patientPageDetection";
import AverageVisitedTimeBaseRenderer from "./overview/averageVisitedTime/averageVisitedTimeBaseRenderer";
import AverageVisitedTimeActionBinder from "./overview/averageVisitedTime/averageVisitedTimeActionBinder";
import ModalService from "./utils/modalService";
import FlowerChartActionBinder from "./overview/flowerChart/flowerChartActionBinder";
import NexusVideoActionBinder from "./overview/nexusVideo/nexusVideoActionBinder";
import contentHeight from "./utils/contentHeight";
import PatientPageState from "./utils/patientPageState";

export default class NexusLayoutPatient {
	constructor(nexusApiCache, menuStructure, patientUrlPattern, patientChangeEnabled, expanded, useSideBarGlobalState, params, useFlexLayout = false, location = new Location()) {
		this.patient = nexusApiCache.patient;
		this.patientPreferences = nexusApiCache.patientPreferences;
		this.patientOverview = nexusApiCache.patientOverview;
		this.patientOverviewForms = nexusApiCache.patientOverviewForms;
		this.nexusVideo = nexusApiCache.nexusVideo;
		this.schoolData = nexusApiCache.schoolData;
		this.flowerChart = nexusApiCache.flowerChart;
		this.home = nexusApiCache.home;
		this.location = location;
		this.errorPopup = new ErrorPopup();
		this.apiAccess = nexusApiCache.apiAccess;

		const menuBuilder = new MenuBuilder(menuStructure, this.location);

		this.patientDefaultPage = new PatientDefaultPage(menuStructure, nexusApiCache.defaultPage, this.location, params.features);
		this.modalService = new ModalService(menuStructure.patientDialogsUrls(), this.location);

		this.basePatientLayoutTemplateRenderer = new BasePatientLayoutTemplateRenderer(undefined, useFlexLayout);
		this.patientMenuDataBuilder = new PatientMenuDataBuilder(menuBuilder);
		this.patientOverviewTemplateRenderer = new PatientOverviewTemplateRenderer();
		this.patientOverviewActionBinder = new PatientOverviewActionBinder(nexusApiCache.eventBus, this.modalService);
		this.patientMenuTemplateRenderer = new PatientMenuTemplateRenderer(menuBuilder);
		this.patientCardsRenderer = new PatientCardsRenderer();
		this.patientCardsActionBinder = new PatientCardsActionBinder(nexusApiCache.eventBus, nexusApiCache.apiAccess,
			this.modalService, this.patientDefaultPage, this.errorPopup);
		this.patientMenuActionBinder = new PatientMenuActionBinder(params.features);

		this.flowerChartRenderer = new FlowerChartRenderer();
		this.flowerChartActionBinder = new FlowerChartActionBinder(this.modalService);

		this.nexusVideoRenderer = new NexusVideoRenderer();
		this.nexusVideoActionBinder = new NexusVideoActionBinder();

		this.schoolDataRenderer = new SchoolDataRenderer();

		this.averageVisitedTimeBaseRenderer = new AverageVisitedTimeBaseRenderer();
		this.averageVisitedTimeActionBinder = new AverageVisitedTimeActionBinder(nexusApiCache.apiAccess, this.errorPopup);

		this.patientPageDetection = new PatientPageDetection(patientUrlPattern, this.location);
		this.expanded = expanded;
		this.useSideBarGlobalState = useSideBarGlobalState;
		this.params = params;
		this.patientChangeEnabled = patientChangeEnabled;

		this.patientPageState = new PatientPageState(this.location);

		this.addEventHandlers(nexusApiCache.eventBus);

		if (pageWasRefreshed()) {
			this.patient.evictAllPatientData(this.patientPageDetection.getPatientId());
			this.home.evict();
		}
	}

	drawPatientLayout(selectorId) {
		this.patientPageState.openNewPage();
		this.basePatientLayoutTemplateRenderer.render(selectorId);
		this.draw();
	}

	reloadPatientLayout() {
		this.patientPageState.openNewPage();
		if (this.patientId !== this.patientPageDetection.getPatientId()) {
			this.clean();
			this.draw();
		} else if (this.patientPageDetection.isPatientPage()) {
			this.patientPageState.setCurrentPageAsPatient();
			this.patientMenuActionBinder.pageChanged();
		}

		if (!this.patientPageDetection.isPatientPage()) {
			this.clean();
		}
	}

	clean() {
		this.basePatientLayoutTemplateRenderer.clean();
		this.patientId = null;
	}

	draw() {
		if (this.patientPageDetection.isPatientPage()) {
			this.basePatientLayoutTemplateRenderer.addBaseClass();
			this.renderPatientData(
				"nexus-patient-layout-core-overview",
				"nexus-patient-layout-core-menu",
				this.patientChangeEnabled,
				this.expanded,
				this.useSideBarGlobalState,
				this.params
			);
		}
	}

	drawPatientOverview(patientId, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState) {
		this.patientPageState.openNewPage();
		this.drawPatientOverviewForPatientId(patientId, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState);
	}

	drawPatientOverviewFromObject(patient, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState) {
		if (patient.id) {
			this.drawPatientOverviewForPatientId(patient.id, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState);
		} else {
			this.renderPatientOverview(patient, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState);
		}
	}

	drawPatientOverviewForPatientId(patientId, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState) {
		this.patientPageState.setCurrentPageAsPatient();
		if (this.patientPageState.isTransitionFromNotPatientStateToPatientState()) {
			this.patient.evictAllPatientData(this.patientPageDetection.getPatientId());
		}
		this.patient.get(patientId)
			.then(
				(patient) => {
					this.renderPatientOverview(patient, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState);
				},
				(reason) => {
					this.errorPopup.handleError(reason);
				}
			);
	}

	renderPatientData(overviewId, menuId, patientChangeEnabled, expanded, useSideBarGlobalState, params) {
		const patientId = this.patientPageDetection.getPatientId();
		this.patientId = patientId;
		this.drawPatientOverviewForPatientId(patientId, overviewId, patientChangeEnabled, expanded, useSideBarGlobalState);
		this.renderPatientMenu(patientId, menuId, params);
	}

	renderPatientMenu(patientId, selectorId, params) {
		const patientPromise = this.patient.get(patientId)
			.then((result) => result)
			.catch((reason) => {
				throw reason;
			});

		const patientPreferencesPromise = this.patientPreferences.get(patientId)
			.then((result) => result)
			.catch((reason) => {
				throw reason;
			});

		const homePromise = this.home.get()
			.then((result) => result)
			.catch((reason) => {
				throw reason;
			});

		Promise.all([patientPromise, patientPreferencesPromise, homePromise])
			.then(
				([patient, patientPreferences, home]) => {
					let professionalId;

					if (home.professional) {
						professionalId = home.professional.id;
					}
					this.patientMenuTemplateRenderer.remove(selectorId);
					const patientMenuData = this.patientMenuDataBuilder.buildPatientData(patient, patientPreferences, params);
					this.patientMenuTemplateRenderer.render(patientMenuData, { ...params, elementId: selectorId });
					this.patientMenuActionBinder.bindActions(patientMenuData, this.location, professionalId);
				},
				(reason) => {
					this.errorPopup.handleError(reason);
				}
			);
	}

	renderPatientOverview(patient, selectorId, patientChangeEnabled, expanded, useSideBarGlobalState) {
		this.patientOverviewTemplateRenderer.remove(selectorId);
		const prefix = (new Date()).getTime();
		const params = { elementId: selectorId, prefix };
		this.patientOverviewTemplateRenderer.render(patient, patientChangeEnabled, this.patientDefaultPage, params);
		this.patientOverviewActionBinder.bindActions(patient, params);
		if (patient.id) {
			this.renderSideBar(patient, selectorId, params, patientChangeEnabled, expanded, useSideBarGlobalState);
		}
		contentHeight.calculate();
	}

	renderSideBar(patient, selectorId, params, patientChangeEnabled, expanded, useSideBarGlobalState) {
		const patientOverviewPromise = this.patientOverview.get(patient.id)
			.then((result) => result)
			.catch((reason) => {
				throw reason;
			});

		const patientOverviewFormsPromise = this.patientOverviewForms.get(patient.id)
			.then((result) => result)
			.catch((reason) => {
				throw reason;
			});

		Promise.all([patientOverviewPromise, patientOverviewFormsPromise])
			.then(
				([patientOverview, patientOverviewForms]) => {
					this.patientCardsRenderer.render(patient, params, patientOverview, patientOverviewForms, patientChangeEnabled);
					this.patientCardsActionBinder.bindActions(patientOverview, selectorId, expanded, useSideBarGlobalState, params.prefix);
					this.renderFlowerChart(patient.id);

					const { nexusVideoConfiguration } = patientOverview.patient;

					if (hasLink(patientOverview.patient, "videoStatus") && nexusVideoConfiguration.allowVideoCalls) {
						this.renderNexusVideoInfo(patientOverview.patient);
					} else if (hasLink(nexusVideoConfiguration, "videoCallConference")) {
						this.renderVideoInfo(nexusVideoConfiguration, true);
					}

					this.renderSchoolData(patientOverview.patient);

					this.renderAverageVisitedTime(patient, params.prefix);
					contentHeight.calculate();
				},
				(reason) => {
					this.errorPopup.handleError(reason);
				}
			);
	}

	renderFlowerChart(patientId) {
		this.flowerChart.get(patientId)
			.then(
				(flowerChartData) => {
					this.flowerChartRenderer.remove();
					this.flowerChartRenderer.render(flowerChartData);
					this.flowerChartActionBinder.bindActions(patientId);
					contentHeight.calculate();
				},
				(reason) => {
					this.errorPopup.handleError(reason);
				}
			);
	}

	renderNexusVideoInfo(patient) {
		this.getVideoStatus(patient)
			.then(
				(response) => {
					this.nexusVideoRenderer.render(response);
					this.nexusVideoActionBinder.bindActions(patient);
				},
				() => {
					this.nexusVideoRenderer.renderError();
				}
			);
	}

	renderVideoInfo(nexusVideoConfiguration, isCallable) {
		this.nexusVideoRenderer.render({ isCallable });
		this.nexusVideoActionBinder.bindActions(nexusVideoConfiguration);
	}

	getVideoStatus(patient) {
		return this.nexusVideo.get(patient.id);
	}

	renderSchoolData(patient) {
		if (hasLink(patient, "schoolDataCurrent")) {
			this.schoolData.get(patient.id)
				.then(
					(response) => {
						this.schoolDataRenderer.render(response);
					},
					() => {
						// Do nothing
					}
				);
		}
	}

	renderAverageVisitedTime(patient, prefix) {
		this.averageVisitedTimeBaseRenderer.render(patient, `${prefix}patient-average-visited-time`, prefix);
		this.averageVisitedTimeActionBinder.bindActions(patient, prefix);
		contentHeight.calculate();
	}

	addEventHandlers(eventBus) {
		eventBus.on("patientEvictedEvent", (event) => {
			this.patientDataReloaded(event.detail.patientId);
		});
		eventBus.on("patientOverviewEvictedEvent", (event) => {
			this.patientOverviewReloaded(event.detail.patientId);
		});
		eventBus.on("patientOverviewFormsEvictedEvent", (event) => {
			this.patientOverviewReloaded(event.detail.patientId);
		});
		eventBus.on("flowerChartEvictedEvent", (event) => {
			this.flowerChartReloaded(event.detail.patientId);
		});
		eventBus.on("patientPreferencesEvictedEvent", (event) => {
			this.patientPreferencesReloaded(event.detail.patientId);
		});
	}

	patientDataReloaded(patientId) {
		if (this.patientId && patientId && +this.patientId === +patientId) {
			this.clean();
			this.draw();
		}
	}

	flowerChartReloaded(patientId) {
		if (this.patientId && patientId && +this.patientId === +patientId) {
			this.renderFlowerChart(this.patientId);
		}
	}

	patientPreferencesReloaded(patientId) {
		if (this.patientId && patientId && +this.patientId === +patientId) {
			this.renderPatientMenu(this.patientId, "nexus-patient-layout-core-menu", this.params);
		}
	}

	patientOverviewReloaded(patientId) {
		if (this.patientId && patientId && +this.patientId === +patientId) {
			this.drawPatientOverviewForPatientId(this.patientId, "nexus-patient-layout-core-overview",
				this.patientChangeEnabled, this.expanded, this.useSideBarGlobalState);
		}
	}
}
