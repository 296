/* eslint-disable no-underscore-dangle */

import template from "lodash/template";
import translate from "../../support/translate";
import htmlToElement from "../../utils/htmlToElement";
import removeChildFromElement from "../../utils/removeChildFromElement";
import calculatePatientAge from "../../utils/calculatePatientAge";
import nexusLayoutPatientOverviewTemplate from "./nexus-layout-patient-overview.tmpl.html";

const compiledPatientOverview = template(nexusLayoutPatientOverviewTemplate);

function prepareParams(patient, patientChangeEnabled, patientDefaultPage, params) {
	return {
		patient,
		translate(key) {
			return translate.instant(key);
		},
		prefix: params.prefix, // prefix is added to all ids in template, because the html elements can be rendered multiple times in one document, e.g. patient modal opened on citizen overview
		colorStyle: color(patient),
		patientUrl: getPatientUrlFunc(patientDefaultPage),
		patientChangeEnabled,
		patientAge: calculatePatientAge(patient.birthDate, patient.deceasedDate ? new Date(patient.deceasedDate) : new Date(Date.now()))
	};
}

export default class PatientOverviewTemplateRenderer {
	constructor(container) {
		this.container = container;
	}

	render(patient, patientChangeEnabled, patientDefaultPage, params) {
		const rootElement = this.getElementById(params.elementId);
		const patientOverviewElement = htmlToElement(compiledPatientOverview(
			prepareParams(patient, patientChangeEnabled, patientDefaultPage, params)
		));
		rootElement.appendChild(patientOverviewElement);
	}

	remove(elementId) {
		const containerElement = this.getElementById(elementId);
		removeChildFromElement(containerElement);
	}

	getElementById(id) {
		if (this.container) {
			return this.container.querySelector(`#${id}`);
		}
		return document.getElementById(id);
	}
}

function color(patient) {
	if (patient.patientState && patient.patientState.color) {
		return patient.patientState.color;
	}
	return undefined;
}

function getPatientUrlFunc(patientDefaultPage) {
	return (patient) => {
		if (!patient || !patient.id) {
			return "#";
		}

		return patientDefaultPage.getCitizenDefaultPage(patient.id);
	};
}
