/* eslint-disable no-underscore-dangle */
import on from "../../utils/on";
import PatientOverviewCtrl from "./patientOverviewCtrl";

export default class PatientOverviewActionBinder {
	constructor(eventBus, modalService, container) {
		this._eventBus = eventBus;
		this.modalService = modalService;
		this.container = container;
	}

	bindActions(patient, params) {
		const patientOverviewCtrl = new PatientOverviewCtrl(
			patient,
			this.modalService
		);
		bindClickActions(params.prefix, patientOverviewCtrl, this.container);
	}
}

function bindClickActions(prefix, patientOverviewCtrl, container) {
	const onClick = on("click");

	onClick.callback(() => { patientOverviewCtrl.changePatient(); })
		.bindById(`${prefix}patient-header-fullname`, container);

	onClick.callback(() => { patientOverviewCtrl.showPhoto(); })
		.bindById(`${prefix}patient-image`, container);
}
