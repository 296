import translate from "../support/translate";

const styles = ""
		+ ".nx-layout-error-modal {"
			+ "display: block;"
			+ "-webkit-overflow-scrolling: touch;"
			+ "z-index: 2000;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog {"
			+ "margin: 100px auto 50px;"
			+ "position: fixed;"
			+ "top: 0;"
			+ "right: 0;"
			+ "bottom: 0;"
			+ "left: 0;"
			+ "z-index: 1080;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content {"
			+ "position: relative;"
			+ "border: 0;"
			+ "border-radius: 0;"
			+ "background-color: #fff;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-header {"
			+ "min-height: 50px;"
			+ "padding: 7px 10px 0;"
			+ "border-bottom: 0;"
			+ "overflow: hidden;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-header .error-modal-title {"
			+ "margin: 0;"
			+ "line-height: 1.428571429;"
			+ "font-weight: 100;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-body {"
			+ "overflow: visible;"
			+ "width: 100%;"
			+ "min-height: 230px;"
			+ "padding: 0;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-body .error-modal-body-inner {"
			+ "padding: 0 20px;"
			+ "white-space: pre-wrap;"
			+ "margin: 10px;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content "
				+ ".error-modal-body .error-modal-body-inner .error-modal-body-scroll {"
			+ "overflow-x: hidden;"
			+ "overflow-y: auto;"
			+ "max-height: 50vh;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-footer {"
			+ "padding: 15px 20px;"
			+ "border-top: 0;"
			+ "text-align: right;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .btn.btn-secondary {"
			+ "border-top: 1px solid #c9c9c9;"
			+ "border-right: 1px solid #c9c9c9;"
			+ "border-left: 1px solid #c9c9c9;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-header .btn,"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-footer .btn {"
			+ "margin: 0 7px 5px 0;"
		+ "}"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-header .btn.btn-secondary,"
		+ ".nx-layout-error-modal .nx-layout-error-modal-dialog .error-modal-content .error-modal-footer .btn.btn-secondary {"
			+ "padding-bottom: 5px;"
		+ "}"
		+ ".nx-layout-error-modal-backdrop {"
			+ "position: fixed;"
			+ "top: 0;"
			+ "right: 0;"
			+ "bottom: 0;"
			+ "left: 0;"
			+ "z-index: 1060;"
			+ "background-color: #000;"
		+ "}"
		+ "div.nx-layout-error-modal-backdrop.in {"
			+ "opacity: 0.8;"
		+ "}"
		+ "@media (min-width: 768px) {"
			+ ".nx-layout-error-modal .nx-layout-error-modal-dialog {"
				+ "width: 600px;"
			+ "}"
		+ "}";

function $(selector) {
	return document.querySelector(selector);
}

function remove(parent, child) {
	if (child) {
		parent.removeChild(child);
	}
}

function removeStyles(head, selector) {
	if (isOneErrorPopupDisplayed()) {
		remove(head, $(selector));
	}
}

function close(prefix) {
	const head = $("head");
	const body = $("body");
	removeStyles(head, "#nx-layout-error-modal-styles");
	remove(body, document.getElementById(`${prefix}nx-layout-error-modal`));
}

const stylesHtml = `<style id="nx-layout-error-modal-styles" type="text/css">${styles}</style>`;

const html = (title, message, prefix) => (
	`<div id='${prefix}nx-layout-error-modal'>`
		+ "<div class='nx-layout-error-modal'>"
			+ "<div class='nx-layout-error-modal-dialog'>"
				+ "<div class='error-modal-content'>"
					+ "<div class='error-modal-header'>"
						+ `<h3 class='error-modal-title'>${title}</h3>`
					+ "</div>"
					+ "<div class='row'>"
						+ "<div class='col-sm-12'>"
							+ "<div class='error-modal-body'>"
								+ `<div class='error-modal-body-inner'>${message}</div>`
							+ "</div>"
						+ "</div>"
					+ "</div>"
					+ "<div class='error-modal-footer'>"
						+ `<button id="${prefix}btn-close-error-button" type='button' class='btn btn-secondary' data-dismiss='modal'>`
							+ `${translate.instant("error_popup_btnClose")}`
						+ "</button>"
					+ "</div>"
				+ "</div>"
			+ "</div>"
		+ "</div>"
		+ "<div class='nx-layout-error-modal-backdrop fade in' />"
	+ "</div>"
);

function isOneErrorPopupDisplayed() {
	const errorPopups = [...document.getElementsByClassName("nx-layout-error-modal")];
	return errorPopups.length === 1;
}

export default function ErrorPopup() {
	function insertStyles() {
		const errorModalStyles = document.getElementById("nx-layout-error-modal-styles");
		if (!errorModalStyles) {
			$("head").insertAdjacentHTML("beforeend", stylesHtml);
		}
	}

	function open(title, message, onClose) {
		const prefix = (new Date()).getTime();
		const closeCallbackFn = () => {
			if (onClose) {
				onClose();
			}
			close(prefix);
		};
		insertStyles();
		$("body").insertAdjacentHTML("beforeend", html(title, message, prefix));
		document.getElementById(`${prefix}btn-close-error-button`).addEventListener("click", closeCallbackFn);
	}

	function getTitle(statusCode) {
		if (statusCode === 503) {
			return translate.instant("error_popup_maintenance_title");
		}
		return translate.instant("error_popup_title");
	}

	this.handleError = (error, onClose) => {
		if (error && error.response !== undefined) {
			const status = error.response ? error.response.status || 0 : 0;
			const message = translate.instant(`error_status_${status}`);
			const title = getTitle(status);
			open(title, message, onClose);
		}
	};
}
