export function contextExists(preferences, context) {
	return typeof preferences[context] !== "undefined";
}

export function ofContext(preferences, context) {
	const contextPreferences = preferences[context];
	return {
		default: contextPreferences.find((preference) => preference.defaultPreference === true),
		withId: (id) => contextPreferences.find((preference) => preference.id === id)
	};
}
