import template from "lodash/template";
import translate from "../../../support/translate";
import htmlToElement from "../../../utils/htmlToElement";
import removeChildFromElement from "../../../utils/removeChildFromElement";
import nexusVideoStatusTemplate from "./nexus-video-status.tmpl.html";
import nexusVideoStatusErrorTemplate from "./nexus-video-status-error.tmpl.html";

const compiledTemplate = template(nexusVideoStatusTemplate);
const compiledErrorTemplate = template(nexusVideoStatusErrorTemplate);

function prepareParams(nexusVideoData) {
	return {
		nexusVideoData,
		translate(key) {
			return translate.instant(key);
		}
	};
}

function prepareErrorParams() {
	return {
		translate(key) {
			return translate.instant(key);
		}
	};
}

export default class NexusVideoRenderer {
	render(data) {
		this.remove();

		if (data.isCallable === true) {
			this.getNexusVideoContainers().forEach((container) => {
				const nexusVideoStatusElement = htmlToElement(compiledTemplate(prepareParams(data)));
				container.appendChild(nexusVideoStatusElement);
			});
		}
	}

	renderError() {
		this.remove();

		this.getNexusVideoContainers().forEach((container) => {
			const nexusVideoStatusErrorElement = htmlToElement(compiledErrorTemplate(prepareErrorParams()));
			container.appendChild(nexusVideoStatusErrorElement);
		});
	}

	remove() {
		this.getNexusVideoContainers().forEach((element) => {
			removeChildFromElement(element);
		});
	}

	getNexusVideoContainers() {
		const containers = document.getElementsByClassName("nexus-video-status");
		if (containers) {
			return [...containers];
		}
		return [];
	}
}
