/* eslint-disable no-restricted-globals */
import viewSpec from "../../utils/viewSpec";
import togglePatientMenu from "./togglePatientMenu";
import isPatientMenuRendered from "./isPatientMenuRendered";
import RecentItemsPatientMenu from "./recentItemsPatientMenu";

function PatientLayoutCtrl(patientMenuData, locationService, professionalId, features) {
	const vm = this;
	vm.patientMenuData = patientMenuData;
	const recentItemsPatientMenu = new RecentItemsPatientMenu(patientMenuData);

	vm.mouseEnterSubMenu = mouseEnterSubMenu;
	vm.mouseLeaveSubMenu = mouseLeaveSubMenu;

	vm.toggleSubMenu = toggleSubMenu;
	vm.selectSubMenuItem = selectSubMenuItem;
	vm.toggleSubnavVisibility = toggleSubnavVisibility;

	vm.setSubnavHeight = setSubnavHeight;
	vm.initSubMenuActive = initSubMenuActive;

	vm.storeClickedElement = storeClickedElement;

	initRecentItems();

	function storeClickedElement(element, childItem) {
		if (features && features["ui.showRecentMenuItems"]) {
			recentItemsPatientMenu.storeClickedElement(professionalId, element, childItem);
		}
	}

	function initRecentItems() {
		if (features && features["ui.showRecentMenuItems"]) {
			recentItemsPatientMenu.initRecentItems(professionalId);
		}
	}

	function openMenuItem(menuItem) {
		if (menuItem.url) {
			locationService.path(menuItem.url);
		}
	}

	function selectSubMenuItem(index, closeAllSubMenus = true) {
		if (closeAllSubMenus) {
			togglePatientMenu.closeAllSubmenu();
		}
		togglePatientMenu.inactiveAllSubmenu();
		if (viewSpec.isXSmallScreen()) {
			toggleSubnavVisibility();
		} else {
			togglePatientMenu.activeSubmenu(index);
		}
	}

	function initSubMenuActive(closeAllSubMenus) {
		const currentPath = location.pathname;
		patientMenuData.forEach((menuItem, index) => {
			const result = pathMatchMenuItemUrl(currentPath, menuItem)
				|| menuItem.submenu.some((submenu) => pathMatchMenuItemUrl(currentPath, submenu));
			if (result) {
				selectSubMenuItem(index, closeAllSubMenus);
			}
		});
	}

	function isSubMenuActive(index) {
		if (togglePatientMenu.isActiveSubmenu(index) || viewSpec.isXSmallScreen()) {
			return togglePatientMenu.isActiveSubmenu(index);
		}
		return undefined;
	}

	function pathMatchMenuItemUrl(currentPath, menuItem) {
		return currentPath === menuItem.url || (!!(menuItem.urlWildcardPattern) && menuItem.urlWildcardPattern.test(currentPath));
	}

	function toggleSubMenu(menuItem, menuItemIndex, $event) {
		$event.stopPropagation();

		if (viewSpec.isXSmallScreen()) {
			// if someone was wondering: on phones activeSubMenu (not hoverSubMenu) is responsible for expanding collapsing / collapsing menu items
			if (isSubMenuActive(menuItem)) {
				togglePatientMenu.inactiveAllSubmenu();
			} else {
				togglePatientMenu.inactiveAllSubmenu();
				togglePatientMenu.activeSubmenu(menuItemIndex);
				// activeSubMenu = menuItem.toggleName;
			}
		} else if (viewSpec.isDeviceCurrentlyWorkingInTouchMode()) {
			// fix for CTZ-1629 - Android invokes toggleSubMenu & mouseEnterSubMenu on one tap, whereas iPad invokes each functions per separate taps
			if (!$event.target.parentNode.classList.contains("hover")) {
				togglePatientMenu.closeAllSubmenu();
				togglePatientMenu.openSubmenu($event.target.parentNode);
			} else {
				if (menuItem.url) {
					togglePatientMenu.inactiveAllSubmenu();
					togglePatientMenu.activeSubmenu(menuItemIndex);
				}
				// hoverSubMenu = null;
				togglePatientMenu.closeAllSubmenu();
				openMenuItem(menuItem);
			}
		} else if (menuItem.url) {
			// hoverSubMenu = null; // close the menu on PC
			togglePatientMenu.inactiveAllSubmenu();
			togglePatientMenu.closeAllSubmenu();
			togglePatientMenu.activeSubmenu(menuItemIndex);
			openMenuItem(menuItem);
		}
	}

	function mouseEnterSubMenu(event) {
		// fix for CTZ-1629 - Android invokes toggleSubMenu & mouseEnterSubMenu on one tap, whereas iPad invokes
		// each of the functions per separate tab so on touch devices this function should do nothing
		// and hover handling should be done in toggleSubMenu
		// * see http://sitr.us/2011/07/28/how-mobile-safari-emulates-mouse-events.html for details
		if (!viewSpec.isDeviceCurrentlyWorkingInTouchMode()) {
			togglePatientMenu.openSubmenu(event.target);
		}
	}

	function mouseLeaveSubMenu(event) {
		togglePatientMenu.closeSubmenu(event.target);
	}

	function setSubnavHeight() {
		if (!isPatientMenuRendered()) {
			return;
		}

		const $subnav = document.getElementsByClassName("subnav")[0];
		const topBar = document.getElementsByClassName("nlc-topbar")[0];
		const ulElements = document.getElementsByClassName("nexus-layout-patient-layout-subelement-ul");
		const ulElementsArray = [...ulElements];
		if (!viewSpec.isXSmallScreen()) {
			const subnavElPadding = 5;
			const topBarHeight = topBar.offsetHeight;
			const windowHeight = viewSpec.windowHeight();
			const subnavHeight = $subnav.clientHeight - (2 * subnavElPadding);
			const dropdownHeight = windowHeight - subnavHeight - ($subnav.offsetTop + topBarHeight) - 24;

			ulElementsArray.forEach((el) => {
				// eslint-disable-next-line no-param-reassign
				el.style.maxHeight = `${dropdownHeight}px`;
			});
		} else {
			ulElementsArray.forEach((el) => {
				// eslint-disable-next-line no-param-reassign
				el.style.maxHeight = "inherit";
			});
		}
	}
	function toggleSubnavVisibility() {
		togglePatientMenu.toggleSubnavVisibility();
	}
	initSubMenuActive(true);
}

export default PatientLayoutCtrl;
