export default class FlowerChartCtrl {
	constructor(patientId, modalService) {
		this.patientId = patientId;
		this.modalService = modalService;
	}

	showFlowerChart() {
		this.modalService.showFlowerChart(this.patientId);
	}
}
