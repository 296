import template from "lodash/template";
import translate from "../../support/translate";
import htmlToElement from "../../utils/htmlToElement";
import hasDefaultItem from "../../utils/hasDefaultItem";
import removeChildFromElement from "../../utils/removeChildFromElement";
import nexusLayoutPatientMenuTemplate from "./nexus-layout-patient-menu.tmpl.html";

const compiledPatientMenu = template(nexusLayoutPatientMenuTemplate);

function prepareParams(patientMenuData) {
	return {
		patientMenuData,
		translate(key) {
			return translate.instant(key);
		},
		hasDefaultItem
	};
}

export default class PatientMenuTemplateRenderer {
	constructor(menuBuilder) {
		this.menuBuilder = menuBuilder;
	}

	render(patientMenuData, params) {
		const rootElement = document.getElementById(params.elementId);
		if (rootElement) {
			const patientMenuElement = htmlToElement(compiledPatientMenu(prepareParams(patientMenuData)));
			rootElement.appendChild(patientMenuElement);
		}
	}

	remove(elementId) {
		const containerElement = document.getElementById(elementId);
		if (containerElement) {
			removeChildFromElement(containerElement);
		}
	}
}
