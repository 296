export default function formatDate(dateString) {
	const dateStringForParsingFormat = dateString.replace(".000+0000", " GMT").replace(/-/g, "/").replace("T", " ");
	const date = new Date(dateStringForParsingFormat);
	return `${formatToTwoDigits(date.getDate())}-${formatToTwoDigits(date.getMonth() + 1)}-${date.getFullYear()}`;

	function formatToTwoDigits(number) {
		if (number.toString().length === 1) {
			return `0${number}`;
		}
		return number;
	}
}
