const STORAGE_NAME = "RecentlyUsedMenuItems";
function getStorageObject() {
	return window.localStorage;
}

function safeAccess(storage) {
	try {
		return storage && storage !== null;
	} catch (e) {
		return false;
	}
}

function buildIdentifier(context, rootName) {
	return `${context}_${rootName}`;
}

function createStorageObjectAndInitialize(professionalId, identifier, subMenuItemName) {
	if (professionalId) {
		const newStorage = {};
		newStorage.professionalId = professionalId;
		newStorage[identifier] = [subMenuItemName];
		getStorageObject().setItem(STORAGE_NAME, JSON.stringify(newStorage));
	}
}

function updateObject(subMenuRecentItems, storageObjectJson, subMenuItemName, identifier) {
	if (subMenuRecentItems.indexOf(subMenuItemName) === -1) {
		subMenuRecentItems.unshift(subMenuItemName);
	}
	const updatedObject = subMenuRecentItems.slice(0, 3);
	/* eslint-disable-next-line no-param-reassign */
	storageObjectJson[identifier] = updatedObject;
	getStorageObject().setItem(STORAGE_NAME, JSON.stringify(storageObjectJson));
}

function addNewObjectToStorage(storageObjectJson, subMenuItemName, identifier) {
	/* eslint-disable-next-line no-param-reassign */
	storageObjectJson[identifier] = [subMenuItemName];
	getStorageObject().setItem(STORAGE_NAME, JSON.stringify(storageObjectJson));
}

export default function storageAccess(menuType) {
	return {
		getValue(professionalId, rootName) {
			if (!safeAccess(getStorageObject())) {
				return undefined;
			}
			const storageObject = getStorageObject().getItem(STORAGE_NAME);
			if (storageObject) {
				const storageObjectJson = JSON.parse(storageObject);
				return professionalId === storageObjectJson.professionalId ? storageObjectJson[buildIdentifier(menuType, rootName)] : undefined;
			}
			return undefined;
		},
		setValue(professionalId, rootName, subMenuItemName) {
			if (!safeAccess(getStorageObject())) {
				return;
			}
			const storageObject = getStorageObject().getItem(STORAGE_NAME);
			const identifier = buildIdentifier(menuType, rootName);
			if (storageObject) {
				const storageObjectJson = JSON.parse(storageObject);
				const subMenuRecentItems = storageObjectJson[identifier];
				if (subMenuRecentItems) {
					updateObject(subMenuRecentItems, storageObjectJson, subMenuItemName, identifier);
				} else {
					addNewObjectToStorage(storageObjectJson, subMenuItemName, identifier);
				}
			} else {
				createStorageObjectAndInitialize(professionalId, identifier, subMenuItemName);
			}
		},

		cleanStorageEntryForNewProfessional(professionalId) {
			if (!safeAccess(getStorageObject())) {
				return;
			}
			const storageObject = getStorageObject().getItem(STORAGE_NAME);
			const storageObjectJson = JSON.parse(storageObject);
			if (storageObjectJson && storageObjectJson.professionalId !== professionalId) {
				getStorageObject().removeItem(STORAGE_NAME);
			}
		}
	};
}
