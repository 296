/* eslint-disable no-trailing-spaces,indent */
import viewSpec from "../../utils/viewSpec";
import hasLink from "../../utils/hasLink";
import sideBarGlobal from "./sideBarGlobal";
import ToggleSideBar from "./toggleSideBar";

function PatientCardsCtrl(patientOverview, patientLayoutElement, eventBus, apiAccess, expanded = false,
	useSideBarGlobalState = false, modalService, defaultPatientPage, errorPopup) {
	const vm = this;

	vm.patientOverview = patientOverview;
	vm.patient = patientOverview.patient;
	vm.doctorContact = patientOverview.doctorContact;
	vm.toggleCards = toggleCards;
	vm.changeDoctorContact = changeDoctorContact;
	vm.changePatient = changePatient;
	vm.editRelativeContact = editRelativeContact;
	vm.showPatientForm = showPatientForm;
	vm.toggleSideBar = new ToggleSideBar(patientLayoutElement);

	function init() {
		if (shouldBeExpandedOnStart()) {
			showCards();
		}
	}

	function changeDoctorContact(event, element) {
		const contactId = element.getAttribute("data-contact-id");

		if (vm.doctorContact.contactType.type === "organization") {
			modalService.changeOrganizationContact(vm.patient.id, contactId);
		} else if (vm.doctorContact.contactType.type === "professional") {
			modalService.changeProfessionalContact(vm.patient.id, contactId);
		}
	}

	function editRelativeContact(event, element) {
		const relativeContactId = element.getAttribute("data-relative-contact-id");
		const relativeContract = vm.patientOverview.relativeContacts.find((relativeContact) => relativeContact.id === (+relativeContactId));
		if (relativeContract && hasLink(relativeContract, "patientSummary")) {
			apiAccess.using(relativeContract).get("patientSummary", {})
				.then(
					(patientSummary) => {
						defaultPatientPage.redirectToPatientDefaultPage(patientSummary.id);
					},
					(reason) => {
						errorPopup.handleError(reason);
					}
				);
		} else if (relativeContract.patientContactSourceType === "MANUAL") {
			modalService.editRelativeContact(vm.patient.id, relativeContactId);
		} else if (relativeContract.patientContactSourceType === "CPR_IMPORTED") {
			modalService.editPatientRelativeContact(vm.patient.id, relativeContactId);
		}
	}

	function changePatient(event, element) {
		if (element.classList.contains("card-clickable")) {
			modalService.changePatient(vm.patient.id);
		}
	}

	function showPatientForm(event, element) {
		const formId = element.getAttribute("data-form-id");
		modalService.showForm(formId);
	}

	function showCards() {
		updateSideBarGlobalState(true);
		vm.toggleSideBar.showSideBar();
	}

	function toggleCards() {
		updateSideBarGlobalState(!sideBarGlobal.isSideBarExpanded());
		vm.toggleSideBar.toggleSideBar();
		eventBus.broadcast("togglePatientCardEvent");
	}

	function updateSideBarGlobalState(value) {
		if (useSideBarGlobalState) {
			sideBarGlobal.setSideBarExpanded(value);
		}
	}

	function shouldBeExpandedOnStart() {
		if (shouldTakeSideBarGlobalState()) {
			return sideBarGlobal.isSideBarExpanded();
		}

		const expandOnWideScreen = expanded === "whenwide" && viewSpec.isMediumOrLargerScreen();
		return expanded === true || expandOnWideScreen;
	}

	function shouldTakeSideBarGlobalState() {
		return useSideBarGlobalState && typeof sideBarGlobal.isSideBarExpanded() === "boolean";
	}

	init();
}

export default PatientCardsCtrl;
