function activeSubmenu(index) {
	const element = document.getElementsByClassName(`nexus-layout-patient-menu-subnavelm-${index}`)[0];
	if (element) {
		element.classList.add("active");
	}
}

function inactiveAllSubmenu() {
	const elements = [...document.getElementsByClassName("nexus-layout-patient-menu-subnavelm")];
	elements.forEach((element) => {
		element.classList.remove("active");
	});
}

function isActiveSubmenu(index) {
	const element = document.getElementsByClassName(`nexus-layout-patient-menu-subnavelm-${index}`)[0];
	if (element) {
		return element.classList.contains("active");
	}
	return undefined;
}

function openSubmenu(element) {
	element.classList.add("hover");
}

function closeSubmenu(element) {
	element.classList.remove("hover");
}

function closeAllSubmenu() {
	const elements = [...document.getElementsByClassName("nexus-layout-patient-menu-subnavelm")];
	elements.forEach(closeSubmenu);
}

function toggleSubnavVisibility() {
	const element = document.getElementById("nexus-layout-patient-menu-subnav");
	if (element) {
		element.classList.toggle("shown");
	}
}

export default {
	openSubmenu,
	closeSubmenu,
	closeAllSubmenu,
	toggleSubnavVisibility,
	activeSubmenu,
	inactiveAllSubmenu,
	isActiveSubmenu

};
