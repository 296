import template from "lodash/template";
import translate from "../../../support/translate";
import htmlToElement from "../../../utils/htmlToElement";
import removeChildFromElement from "../../../utils/removeChildFromElement";
import flowerChartTemplate from "./flower-chart.tmpl.html";

const compiledTemplate = template(flowerChartTemplate);

function prepareParams(flowerChartData, prefix) {
	return {
		flowerChartData,
		prefix,
		translate(key) {
			return translate.instant(key);
		}
	};
}

export default class FlowerChartRenderer {
	render(flowerChartData) {
		this.remove();
		const containers = document.getElementsByClassName("patient-flower-chart-card");
		if (containers) {
			[...containers].forEach((container) => {
				const prefix = (new Date()).getTime() + Math.floor(Math.random() * 1000);
				const flowerChartElement = htmlToElement(compiledTemplate(prepareParams(flowerChartData, prefix)));
				container.appendChild(flowerChartElement);
				if (flowerChartData) {
					const flowerChartWrapper = document.getElementById(`${prefix}flower-chart-wrapper-id`);
					flowerChartWrapper.innerHTML = flowerChartData;
				}
			});
		}
	}

	remove() {
		const containerElement = document.getElementsByClassName("patient-flower-chart-card");
		[...containerElement].forEach((element) => {
			removeChildFromElement(element);
		});
	}
}
