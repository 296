const headerAndMenuHeight = 122;

export default {
	calculate() {
		const patientCitizenBanner = document.getElementsByClassName("patient-cards-inner");
		if (patientCitizenBanner.length > 0) {
			const patientCitizenBannerHeight = patientCitizenBanner[0].offsetHeight - headerAndMenuHeight;
			const patientLayoutContent = document.getElementById("nexus-patient-layout-core-content");
			if (patientLayoutContent && patientCitizenBannerHeight > 0) {
				patientLayoutContent.style.minHeight = `${patientCitizenBannerHeight}px`;
			}
		}
	}
};
