import { MAIN_MENU_CONTEXT } from "../../menuBuilder/menuType";
import storageAccess from "../../utils/recentlyUsedItemStorage";

function RecentItemsMenu() {
	const vm = this;
	const storage = storageAccess(MAIN_MENU_CONTEXT);
	vm.storeClickedElement = storeClickedElement;
	vm.initRecentItemsForMenu = initRecentItemsForMenu;
	const submenuLengthRequirement = 10;
	const recentItemLength = 3;

	function storeClickedElement(professionalId, event) {
		if (countSubMenuItems(event) > submenuLengthRequirement && professionalId) {
			storage.setValue(professionalId, getContextMenuLabel(event), event.target.text);
			updateRecentItemsForMenu(professionalId, getContextMenuLinkElement(event));
		}
	}

	function initRecentItemsForMenu(professionalId) {
		if (!professionalId) {
			return;
		}
		storage.cleanStorageEntryForNewProfessional(professionalId);
		const contextMenuList = getContextMenusList();
		contextMenuList.forEach((contextMenu) => {
			const contextMenuTitle = contextMenu.getElementsByClassName("nexus-layout-desktop-menu-a-icon")[0].text;
			const stored = storage.getValue(professionalId, contextMenuTitle.trim());
			const placeholders = contextMenu.getElementsByClassName("placeholder");
			if (placeholders && placeholders.length > 0) {
				updateRecentItemPlaceholders(stored, placeholders, contextMenu);
			}
		});
	}

	function updateRecentItemsForMenu(professionalId, contextMenuElement) {
		const contextMenus = getContextMenusList();
		contextMenus.forEach((contextMenu) => {
			if (contextMenuElement.text === contextMenu.getElementsByClassName("nexus-layout-desktop-menu-a-icon")[0].text) {
				const stored = storage.getValue(professionalId, contextMenuElement.text.trim());
				const placeholders = contextMenu.getElementsByClassName("placeholder");
				updateRecentItemPlaceholders(stored, placeholders, contextMenu);
			}
		});
	}

	function enableMenuDivider(contextMenu) {
		const menuDivider = contextMenu.getElementsByClassName("menuDivider")[0];
		if (menuDivider.classList.contains("hidden-element")) {
			menuDivider.classList.remove("hidden-element");
		}
	}

	function updateRecentItemPlaceholders(stored, placeholders, contextMenu) {
		if (stored) {
			enableMenuDivider(contextMenu);
			for (let i = 0; i < recentItemLength; i += 1) {
				if (stored[i] && placeholders && placeholders.length > 0) {
					const existingItemElements = Array.from(contextMenu.getElementsByClassName("nexus-layout-left-menu-sub-element"));
					const itemElementToClone = existingItemElements.filter((existingItem) => existingItem.text === stored[i])[0];
					if (itemElementToClone) {
						fillPlaceholderWithMenuItem(itemElementToClone, placeholders[i]);
					}
				}
			}
		}
	}

	function fillPlaceholderWithMenuItem(itemElementToClone, placeholder) {
		const linkElement = placeholder.getElementsByClassName("nexus-layout-left-menu-sub-element")[0];
		const placeholderElement = placeholder;
		linkElement.setAttribute("href", itemElementToClone.getAttribute("href"));
		placeholderElement.classList.remove("hidden-element");
		linkElement.text = itemElementToClone.text;
	}

	function getContextMenusList() {
		return Array.from(document.getElementsByClassName("nexus-layout-menu-main"));
	}

	function getContextMenuLabel(event) {
		const contextMenuElement = getContextMenuLinkElement(event);
		if (contextMenuElement) {
			return contextMenuElement.text ? contextMenuElement.text.trim() : contextMenuElement.text;
		}
		return undefined;
	}

	function getContextMenuLinkElement(event) {
		const parentMenu = getContextMenuElement(event);
		if (parentMenu) {
			return parentMenu.getElementsByClassName("nexus-layout-desktop-menu-a-icon")[0];
		}
		return undefined;
	}

	function countSubMenuItems(event) {
		const parentMenu = getContextMenuElement(event);
		if (parentMenu) {
			return parentMenu.getElementsByClassName("nexus-layout-left-menu-sub-element").length;
		}
		return undefined;
	}

	function getContextMenuElement(event) {
		const path = event.path || (event.composedPath && event.composedPath());
		if (path) {
			return path.find((item) => item.classList.contains("nexus-layout-menu-main"));
		}
		return undefined;
	}
}

export default RecentItemsMenu;
