import viewSpec from "../../utils/viewSpec";
import autoComplete from "./autoComplete";

export default class MenuSearch {
	constructor(home, apiAccess, location, searchUrls, errorPopup, modalService) {
		this.home = home;
		this.apiAccess = apiAccess;
		this.searchUrls = searchUrls;
		this.location = location;
		this.errorPopup = errorPopup;
		this.modalService = modalService;
	}

	init() {
		const $this = this;
		const cancellationToken = { cancel() {} };

		autoComplete({
			selector: ".nexus-core-layout-search-input",
			parentElement: document.querySelector("#nexus-layout-core-topbar .navbar-form"),
			menuClass: "nexus-core-search-dropdown-menu",
			minChars: 0,
			offsetTop: 5,
			offsetLeft: 115,
			source(term, suggest) {
				if ($this.home.isCached()) {
					search($this.apiAccess, $this.home.getCached(), term, suggest, cancellationToken);
				} else {
					$this.home.get().then((home) => {
						search($this.apiAccess, home, term, suggest, cancellationToken);
					});
				}
			},
			objectToString(object) {
				return getPatientString(object.patient);
			},
			onSelect(e, term, item, object) {
				$this.onSelect(object);
			},
			onSubmit(e, term) {
				cancellationToken.cancel();
				e.preventDefault();
				$this.onSubmit(e, term);
			},
			onBlur() {
				hideSearchFormForMobileScreen();
			},
			onWindowResize() {
				if (viewSpec.isNotSmallScreen()) {
					hideSearchForm();
				}
			}
		});
	}

	onSelect(object) {
		if (object && object.patient) {
			const patientId = object.patient.id;
			const patientUrl = this.searchUrls.patient(patientId);
			if (object.patient.patientStatus === "DRAFT") {
				this.modalService.changePatient(patientId);
			} else {
				this.location.path(patientUrl.url, patientUrl.params);
			}
			hideSearchFormForMobileScreen();
		}
	}

	submitSearchForm(event) {
		event.preventDefault();
		const input = document.querySelector(".nexus-core-layout-search-input");
		const term = input.value;
		if (term && term.trim().length > 0) {
			this.onSubmit(event, term.trim());
			clearInput(input);
		}
	}

	onSubmit(e, term) {
		const patientListUrl = this.searchUrls.patientsList(term);
		this.location.path(patientListUrl.url, patientListUrl.params);
	}

	onClickMobileSearchIcon() {
		const searchForm = document.querySelector("#nexus-layout-core-topbar .navbar-form");
		searchForm.classList.add("nexus-core-search-form-show");
		const input = searchForm.querySelector(".nexus-core-layout-search-input");
		input.focus();
	}
}

function getPatientString(patient) {
	return `${patient.fullName}, ${patient.patientIdentifier.identifier}, ${getPatientCurrentAddress(patient)}`;
}

function getPatientCurrentAddress(patient) {
	const currentAddressLines = [patient.currentAddress.addressLine1,
		patient.currentAddress.addressLine2,
		patient.currentAddress.addressLine3,
		patient.currentAddress.addressLine4,
		patient.currentAddress.addressLine5].join(" ").trim();
	const currentAddressPostal = [patient.currentAddress.postalCode, patient.currentAddress.postalDistrict].join(" ").trim();
	return [currentAddressLines, currentAddressPostal].join(", ");
}

function search(apiAccess, object, term, suggest, cancellationToken) {
	cancellationToken.cancel();
	apiAccess.using(object).get("searchPatientsOrderedByLastVisited", { query: encodeURI(term) }, cancellationToken)
		.then(
			(data) => {
				suggest(data);
			},
			(reason) => {
				this.errorPopup.handleError(reason);
			}
		);
}

function clearInput(input) {
	// eslint-disable-next-line no-param-reassign
	input.value = "";
	input.blur();
}

function hideSearchForm() {
	const searchForm = document.querySelector("#nexus-layout-core-topbar .navbar-form");
	searchForm.classList.remove("nexus-core-search-form-show");
}

function hideSearchFormForMobileScreen() {
	if (viewSpec.isSmallScreen()) {
		hideSearchForm();
	}
}
