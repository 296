import template from "lodash/template";
import translate from "../../../support/translate";
import htmlToElement from "../../../utils/htmlToElement";
import contentTemplate from "./average-visited-time-button.tmpl.html";

const params = (prefix) => ({
	prefix,
	translate(key) {
		return translate.instant(key);
	}
});

const compiledTemplate = template(contentTemplate);

export default class AverageVisitedTimeButtonRenderer {
	render(containerId, prefix) {
		const container = document.getElementById(containerId);
		if (container) {
			const content = htmlToElement(compiledTemplate(params(prefix)));
			container.appendChild(content);
		}
	}
}
