import template from "lodash/template";
import htmlToElement from "../../utils/htmlToElement";
import nexusLayoutTemplate from "./nexus-layout-patient-core.tmpl.html";
import flexTemplate from "./flex-template.tmpl.html";

const compiledNexusLayout = template(nexusLayoutTemplate);
const compiledFlexLayout = template(flexTemplate);

export default class BasePatientLayoutTemplateRenderer {
	constructor(container, useFlexLayout = false) {
		this.container = container;
		this.useFlexLayout = useFlexLayout;
	}

	render(selectorId) {
		const container = this.container ? this.container : document.getElementById(selectorId);

		const patientBaseLayoutElement = htmlToElement(this.useFlexLayout ? compiledFlexLayout() : compiledNexusLayout());
		const applicationElements = [...container.children];
		if (applicationElements && applicationElements.length > 0) {
			const firstApplicationContent = applicationElements[0];
			container.insertBefore(patientBaseLayoutElement, firstApplicationContent);
			const applicationBody = container.querySelector("#nexus-patient-layout-core-content");
			applicationElements.forEach((applicationElement) => applicationBody.appendChild(applicationElement));
		} else {
			container.appendChild(patientBaseLayoutElement);
		}
	}

	addBaseClass() {
		if (!this.useFlexLayout) {
			this.getElementById("nexus-patient-layout-core-row").classList.add("row");
			this.getElementById("nexus-patient-layout-core-col").classList.add("col-xs-12");
			this.getElementById("nexus-patient-layout-core-patient-container").classList.add("patient-container");
		} else {
			this.getElementById("nexus-patient-layout-core-row").classList.add("nlc-scrollable-y");
			this.getElementById("nexus-patient-layout-core-content").classList.remove("nlc-full-size");
			this.getElementById("nexus-patient-layout-core-col").classList.remove("nlc-scroll-hidden");
			this.getElementById("nexus-patient-layout-core-patient-container").classList.remove("nlc-scroll-hidden");
			this.getElementById("nexus-patient-layout-core-patient-overview-container").classList.remove("nlc-scroll-hidden");
		}
	}

	clean() {
		let childNodes = [...document.getElementById("nexus-patient-layout-core-overview").childNodes];
		childNodes.forEach((child) => document.getElementById("nexus-patient-layout-core-overview").removeChild(child));
		childNodes = [...document.getElementById("nexus-patient-layout-core-menu").childNodes];
		childNodes.forEach((child) => document.getElementById("nexus-patient-layout-core-menu").removeChild(child));
		document.getElementById("nexus-patient-layout-core-row").classList.remove("row");
		document.getElementById("nexus-patient-layout-core-col").classList.remove("col-xs-12");
		document.getElementById("nexus-patient-layout-core-patient-container").classList.remove("cards-active");
		document.getElementById("nexus-patient-layout-core-patient-container").classList.remove("patient-container");

		if (this.useFlexLayout) {
			this.getElementById("nexus-patient-layout-core-row").classList.remove("nlc-scrollable-y");
			this.getElementById("nexus-patient-layout-core-content").classList.add("nlc-full-size");
			this.getElementById("nexus-patient-layout-core-col").classList.add("nlc-scroll-hidden");
			this.getElementById("nexus-patient-layout-core-patient-container").classList.add("nlc-scroll-hidden");
			this.getElementById("nexus-patient-layout-core-patient-overview-container").classList.add("nlc-scroll-hidden");
			this.getElementById("nexus-patient-layout-core-patient-overview-container").classList.remove("cards-active");
		}
	}

	getElementById(id) {
		if (this.container) {
			return this.container.querySelector(`#${id}`);
		}
		return document.getElementById(id);
	}
}
