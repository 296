function mapParamsToQuery(params) {
	let search = "";
	if (params) {
		search += "?";
		Object.keys(params)
			.forEach((key, index) => {
				if (index > 1) {
					search += "&";
				}
				search += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
			});
	}
	return search;
}

class WindowLocation {
	path(path, params) {
		const query = mapParamsToQuery(params);
		WindowLocation.href(path + query);
	}

	href(href) {
		WindowLocation.href(href);
	}

	replace(path, params) {
		WindowLocation.replace(path, params);
	}

	static href(href) {
		window.location.href = href;
	}

	static replace(path, params) {
		const query = mapParamsToQuery(params);
		window.location.replace(path + query);
	}
}

class ExternalHrefLocation {
	constructor(locationService) {
		this.locationService = locationService;
	}

	path(path, params) {
		const query = mapParamsToQuery(params);
		this.locationService.path(path + query);
	}

	replace(path, params) {
		const query = mapParamsToQuery(params);
		this.locationService.replace(path + query);
	}

	href(href) {
		this.locationService.path(href);
	}
}

class ExternalLocation {
	constructor(locationService) {
		this.locationService = locationService;
		this.parser = document.createElement("a");
	}

	path(path, params) {
		this.locationService.path(path, params);
	}

	replace(path, params) {
		this.locationService.replace(path, params);
	}

	href(href) {
		this.parser.href = href;
		const pathname = ExternalLocation.withSlash(this.parser.pathname);
		const search = {};
		if (this.parser.search && this.parser.search.length > 0) {
			const params = this.parser.search.substring(1).split("&");// format ?key1=value&key2=value
			params.forEach((param) => {
				const values = param.split("=");
				if (values.length === 2) {
					// eslint-disable-next-line prefer-destructuring
					search[values[0]] = values[1];
				}
			});
		}

		this.locationService.path(pathname, search);
	}

	static withSlash(path) {
		return (path.substr(0, 1) !== "/" ? "/" : "") + path;
	}
}

export default class Location {
	constructor() {
		this.impl = new WindowLocation();
	}

	path(path, params) {
		this.impl.path(path, params);
	}

	replace(path, params) {
		this.impl.replace(path, params);
	}

	href(href) {
		this.impl.href(href);
	}

	withExternalLocationService(locationService) {
		this.impl = new ExternalLocation(locationService);
	}

	withExternalHrefLocationService(locationService) {
		this.impl = new ExternalHrefLocation(locationService);
	}

	getPathname() {
		return window.location.pathname;
	}

	getSearch() {
		return window.location.search;
	}

	getHash() {
		return window.location.hash;
	}

	getHref() {
		return window.location.href;
	}

	getFullPath() {
		return this.getPathname() + this.getSearch() + this.getHash();
	}
}
