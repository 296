import MenuBuilder from "../../menuBuilder/menuBuilder";
import PatientPageProvider from "./patientPageProvider";

class PatientDefaultPage {
	constructor(menuStructure, defaultPageProvider, location, features) {
		this.location = location;
		this.menuStructure = menuStructure;
		this.menuBuilder = new MenuBuilder(menuStructure, location);
		this.patientPageProvider = new PatientPageProvider(menuStructure);
		this.defaultPageProvider = defaultPageProvider;
		this.features = features;
	}

	goToPatientPage = (patient, patientPreferences, context = null, filterId = null, params = {}) => {
		if (context === null && filterId === null) {
			this.defaultPageProvider.get()
				.then((defaultPage) => {
					if (defaultPage !== null) {
						this.tryToGoToPage(patient, patientPreferences, params, defaultPage.view.defaultPatientPage);
					} else {
						this.goToDefaultContext(patient, patientPreferences, params);
					}
				});
		} else {
			this.tryToGoToPage(patient, patientPreferences, params, context, filterId);
		}
	};

	redirectToPatientDefaultPage = (patientId) => {
		this.location.path(this.getCitizenDefaultPage(patientId), {});
	};

	tryToGoToPage = (patient, patientPreferences, params, pageContext, filterId) => {
		const page = this.patientPageProvider.getPatientPageByFiltersContext(patient, patientPreferences, pageContext, filterId);
		if (page.isPageDisplayable()) {
			if (page.isDefaultFilterDefined() || page.isFilterDefined()) {
				this.goToPage(page, params);
			} else {
				this.goToUrl(this.menuBuilder.noPreferenceUrls.patient(pageContext, patient.id), params);
			}
		} else {
			this.goToDefaultContext(patient, patientPreferences, params);
		}
	};

	goToDefaultContext = (patient, patientPreferences, params) => {
		const pageContext = this.features && this.features["ui.preferences.defaultDashboards"] ? "CITIZEN_DASHBOARD" : "CITIZEN_PATHWAY";
		this.tryToGoToPage(patient, patientPreferences, params, pageContext);
	};

	goToPage = (page, params) => {
		this.goToUrl(page.getUrl(), params);
	};

	goToUrl = (url, params) => {
		this.location.replace(url, params);
	};

	getCitizenDefaultPage(patientId) {
		const path = this.location.getPathname();
		return this.menuStructure.getDefaultPatientPage(this.menuStructure.getApplicationNameForPath(path), patientId);
	}
}

export default PatientDefaultPage;
