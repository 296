import on from "../../../utils/on";
import AverageVisitedTimeCtrl from "./averageVisitedTimeCtrl";

export default class AverageVisitedTimeActionBinder {
	constructor(apiAccess, errorPopup) {
		this.apiAccess = apiAccess;
		this.errorPopup = errorPopup;
	}

	bindActions(patient, prefix) {
		const ctrl = new AverageVisitedTimeCtrl(this.apiAccess, patient, prefix, this.errorPopup);
		bindClickActions(ctrl, prefix);
	}
}

function bindClickActions(ctrl, prefix) {
	const onClick = on("click");

	onClick
		.callback(() => ctrl.showAverageVisitedTime())
		.bindById(`${prefix}nexus-layout-patient-average-visited-time-button`);
}
