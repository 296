export default class PatientPageDetection {
	constructor(patientUrlPattern, location) {
		this.location = location;
		this.patientUrlPattern = patientUrlPattern;
	}

	getPatientId() {
		const pathname = this.location.getPathname();
		const matches = this.patientUrlPattern.exec(pathname);
		return matches ? matches[1] : matches;
	}

	isPatientPage() {
		const pathname = this.location.getPathname();
		return this.patientUrlPattern.test(pathname);
	}
}
