const key = "nexus_layout_core_patient_layout_page_state";

function getDefaultStore() {
	return sessionStorage;
}

class Page {
	constructor({ url, isPatientState } = { url: "", isPatientState: false }) {
		this.url = url;
		this.isPatientState = isPatientState;
	}

	setAsPatientState() {
		this.isPatientState = true;
	}

	isPageChanged(page) {
		return this.url !== page.url;
	}
}

function getPreviousPage(store) {
	const pageState = getPageState(store);
	if (pageState.previousPage) {
		return new Page(pageState.previousPage);
	}
	return new Page();
}

function getCurrentPage(store) {
	const pageState = getPageState(store);
	if (pageState.currentPage) {
		return new Page(pageState.currentPage);
	}
	return new Page();
}

function getPageState(store) {
	const pageState = store.getItem(key);
	if (pageState) {
		return JSON.parse(pageState);
	}
	return {};
}

function getNewPage(location) {
	return new Page({
		url: location.getHref(),
		isPatientState: false
	});
}

function save(store, { currentPage, previousPage }) {
	store.setItem(key, JSON.stringify({ currentPage, previousPage }));
}

class PatientPageState {
	constructor(location, store = getDefaultStore()) {
		this.location = location;
		this.store = store;
	}

	isTransitionFromNotPatientStateToPatientState() {
		const previousPage = getPreviousPage(this.store);
		const currentPage = getCurrentPage(this.store);
		return !previousPage.isPatientState && currentPage.isPatientState;
	}

	setCurrentPageAsPatient() {
		const previousPage = getPreviousPage(this.store);
		const currentPage = getCurrentPage(this.store);
		currentPage.setAsPatientState();

		save(this.store, { currentPage, previousPage });
	}

	openNewPage() {
		const newPage = getNewPage(this.location, false);
		const currentPage = getCurrentPage(this.store);

		if (currentPage.isPageChanged(newPage)) {
			save(this.store, { currentPage: newPage, previousPage: currentPage });
		}
	}
}

export default PatientPageState;
