const SIDE_BAR_EXPANDED = "nexus_layout_side_bar_expanded";
function isSideBarExpanded() {
	const sideBarExpanded = sessionStorage.getItem(SIDE_BAR_EXPANDED);
	if (typeof sideBarExpanded === "undefined" || sideBarExpanded === null) {
		return undefined;
	}
	return sideBarExpanded === "true";
}

function setSideBarExpanded(expanded) {
	if (typeof expanded === "boolean") {
		sessionStorage.setItem(SIDE_BAR_EXPANDED, expanded);
	}
}

export default {
	isSideBarExpanded,
	setSideBarExpanded
};
