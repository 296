import template from "lodash/template";
import translate from "../../../support/translate";
import htmlToElement from "../../../utils/htmlToElement";
import hasLink from "../../../utils/hasLink";
import contentTemplate from "./average-visited-time-base.tmpl.html";
import AverageVisitedTimeButtonRenderer from "./averageVisitedTimeButtonRenderer";

const params = (prefix) => ({
	prefix,
	translate(key) {
		return translate.instant(key);
	}
});

const compiledTemplate = template(contentTemplate);

export default class AverageVisitedTimeBaseRenderer {
	constructor() {
		this.averagetVisitedTimeButtonRenderer = new AverageVisitedTimeButtonRenderer();
	}

	render(patient, containerId, prefix) {
		const container = document.getElementById(containerId);
		if (container && hasLink(patient, "averageVisitedTimeLink")) {
			const content = htmlToElement(compiledTemplate(params(prefix)));
			container.appendChild(content);
			this.averagetVisitedTimeButtonRenderer.render(`${prefix}nexus-layout-patient-average-visited-time-content`, prefix);
		}
	}
}
